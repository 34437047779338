import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useCmsStore } from 'web/src/modules/cms/store';
let CasinoBetgamesPagePrefetch = class CasinoBetgamesPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        try {
            await useCmsStore().fetchCmsContentTranslation({
                key: 'WEB2_BETGAMES_DESCRIPTION',
                silent: true
            });
        } catch  {}
        // empty
        next();
    }
};
export { CasinoBetgamesPagePrefetch as default };
