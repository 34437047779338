import { onBeforeUnmount, onMounted, toRef } from 'vue';
import { Timer } from '@leon-hub/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCashbackStore } from 'web/src/modules/promotions/store';
export default function useCasinoGameCashback() {
    const cashbackStore = useCashbackStore();
    const isCashbackDisplay = toRef(cashbackStore, 'isCashbackDisplay');
    const timestampStartDate = toRef(cashbackStore, 'timestampStartDate');
    const timestampEndDate = toRef(cashbackStore, 'timestampEndDate');
    const scgStore = useSiteConfigStore();
    const isWeb2BonusCashbackWidgetEnabled = toRef(scgStore, 'isWeb2BonusCashbackWidgetEnabled');
    let beforeStartCashbackTimeoutId = 0;
    function stopCashbackTimer() {
        if (beforeStartCashbackTimeoutId) {
            Timer.clearTimeout(beforeStartCashbackTimeoutId);
            beforeStartCashbackTimeoutId = 0;
        }
    }
    async function setBeforeStartCashbackTimer() {
        const currentTimestamp = Date.now();
        await cashbackStore.getCashback();
        stopCashbackTimer();
        if (currentTimestamp < timestampStartDate.value) {
            const delay = timestampStartDate.value - currentTimestamp;
            beforeStartCashbackTimeoutId = Timer.setTimeout(()=>{
                cashbackStore.setCashbackActive(true);
            }, delay);
        } else if (currentTimestamp < timestampEndDate.value) cashbackStore.setCashbackActive(true);
    }
    onMounted(()=>{
        if (isWeb2BonusCashbackWidgetEnabled.value) setBeforeStartCashbackTimer();
    });
    onBeforeUnmount(stopCashbackTimer);
    return {
        isCashbackDisplay
    };
}
