import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import RouteName from '@leon-hub/routing-config-names';
import getBaseHeaders, { languageHeaderName, themeHeaderName } from '@leon-hub/api/src/client/getBaseHeaders';
import resolveApi2Url from '@leon-hub/api/src/helpers/resolveApi2Url';
import { removeQueryParameters } from '@leon-hub/utils';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useTheme } from 'web/src/modules/theme/composables';
import { newWindowExitUrl } from './constants';
export default function useCasinoGameExitUrls() {
    const router = useRouter();
    const { theme } = useTheme();
    const gamesStore = useCasinoGameStore();
    const backToLobbyLocation = toRef(gamesStore, 'activeGameBackToLobbyLocation');
    const closeUrl = computed(()=>router.resolve(backToLobbyLocation.value).fullPath);
    const bankingLocation = {
        name: RouteName.DEPOSITS
    };
    function getExitPath(url) {
        let baseUrl = getLocationOrigin();
        const urlParameter = removeQueryParameters(url);
        baseUrl += `/egs-exit.html?url=${urlParameter}`;
        return baseUrl;
    }
    function getExitHref() {
        return router.getPrevHistoryUrl() || closeUrl.value;
    }
    function getBankingHref() {
        return router.resolve(bankingLocation).fullPath;
    }
    function getBankingUrl() {
        return getExitPath(getBankingHref());
    }
    function getExitUrl() {
        return getExitPath(getExitHref());
    }
    function getNewWindowUrl(gameId, isDemo) {
        const nextHeaders = {
            [languageHeaderName]: useI18nStore().locale,
            [themeHeaderName]: theme.value.toLowerCase()
        };
        const headers = getBaseHeaders(nextHeaders);
        const parameters = Object.keys(headers).map((key)=>`${key.toLowerCase()}=${headers[key]}`).join('&');
        const server = resolveApi2Url();
        return `${server}/externalgames/start/${gameId}?isDemo=${isDemo}&${parameters}&exitUrl=${newWindowExitUrl}&bankingUrl=${getBankingUrl()}`;
    }
    return {
        backToLobbyLocation,
        bankingLocation,
        closeUrl,
        getBankingUrl,
        getExitUrl,
        getExitHref,
        getBankingHref,
        getNewWindowUrl
    };
}
