import { computed, ref } from 'vue';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import BetgamesWidgetPostMessageEvent, { BetgamesWidgetPostMessageBusInitiator } from 'web/src/modules/framed-app/components/BetgamesFramedWidget/utils/BetgamesWidgetPostMessageEvent';
import { getDefaultWidgetUrl } from 'web/src/modules/framed-app/utils';
export default function useCasinoBetgamesIframeWidget(props, emits) {
    const iframeName = IframeWidget.BetGames;
    const iframeHeight = ref(500);
    let postMessageBus = null;
    const gameUrl = computed(()=>props.iframeUrl ? `${props.iframeUrl}/${iframeName}` : getDefaultWidgetUrl(iframeName));
    function iframeMounted() {
        const iframeContentWindow = requireContentWindowByIFrameName(iframeName);
        postMessageBus = new PostMessageBus({
            target: iframeContentWindow,
            targetOrigin: '*',
            initiator: BetgamesWidgetPostMessageBusInitiator
        });
        postMessageBus.emit(BetgamesWidgetPostMessageEvent.init, {
            gameHtml: props.gameHtml
        });
        postMessageBus.on(BetgamesWidgetPostMessageEvent.stateChanged, ()=>{
            postMessageBus?.emit(BetgamesWidgetPostMessageEvent.init, {
                gameHtml: props.gameHtml
            });
        });
        postMessageBus.on(BetgamesWidgetPostMessageEvent.changeHeight, (param)=>{
            let { height } = param;
            iframeHeight.value = height;
        });
    }
    function emitLoad() {
        emits('load');
    }
    return {
        iframeName,
        iframeHeight,
        gameUrl,
        iframeMounted,
        emitLoad
    };
}
