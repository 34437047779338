import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import CasinoProviderSelect from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/CasinoProviderSelect.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSidebarFilterBlock',
    props: {
        activeGroupName: {},
        hasGroupName: {
            type: Boolean
        }
    },
    emits: [
        "click-groups",
        "click-search",
        "click-help"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function clickGroups(event) {
            emit('click-groups', event);
        }
        function clickSearch(event) {
            emit('click-search', event);
        }
        const iconSearchProperty = {
            name: IconName.SEARCH,
            size: IconSize.SIZE_20
        };
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-sidebar-filter-block'])
            }, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(_ctx.$style['casino-sidebar-filter-block__search']),
                    onClick: clickSearch
                }, [
                    _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(iconSearchProperty)), null, 16),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_CASINO_SIDEBAR_SEARCH')), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'casino-sidebar-search'
                        }
                    ]
                ]),
                _createVNode(CasinoProviderSelect, {
                    title: _ctx.activeGroupName,
                    "has-selected-group": _ctx.hasGroupName,
                    "in-sidebar": "",
                    class: _normalizeClass(_ctx.$style['casino-sidebar-filter-block__provider']),
                    onClick: clickGroups
                }, null, 8, [
                    "title",
                    "has-selected-group",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoSidebarFilterBlock'
                ]
            ]);
        };
    }
});
