import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { useGetIcon } from 'web/src/modules/casino/submodules/lobby/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSidebarNavigationBlock',
    props: {
        list: {},
        isLoading: {
            type: Boolean
        }
    },
    setup (__props) {
        const { getIcon } = useGetIcon();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-sidebar-navigation-block'])
            }, [
                _ctx.isLoading ? (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 1
                }, _renderList(_ctx.list, (item)=>(_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: _normalizeClass(_ctx.$style['casino-sidebar-navigation-block__loading-item'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                "skeleton-loading",
                                _ctx.$style['casino-sidebar-navigation-block__loading-icon']
                            ])
                        }, null, 2),
                        _createElementVNode("div", {
                            class: _normalizeClass([
                                "skeleton-loading",
                                _ctx.$style['casino-sidebar-navigation-block__loading-text']
                            ])
                        }, null, 2)
                    ], 2))), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                    key: 0
                }, _renderList(_ctx.list, (item)=>(_openBlock(), _createBlock(VRouterLink, {
                        key: item.id,
                        class: _normalizeClass({
                            [_ctx.$style['casino-sidebar-navigation-block__item']]: true,
                            [_ctx.$style['casino-sidebar-navigation-block__item--active']]: item.isActive
                        }),
                        to: item.location
                    }, {
                        default: _withCtx(()=>[
                                item.iconUrl ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createVNode(VImage, {
                                        class: _normalizeClass(_ctx.$style['casino-sidebar-navigation-block__image']),
                                        src: item.iconUrl
                                    }, null, 8, [
                                        "class",
                                        "src"
                                    ])
                                ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 1,
                                    name: _unref(getIcon)(item.id)
                                }, null, 8, [
                                    "name"
                                ])),
                                _createElementVNode("span", null, _toDisplayString(item.name), 1)
                            ]),
                        _: 2
                    }, 1032, [
                        "class",
                        "to"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoSidebarNavigationBlock'
                ]
            ]);
        };
    }
});
