import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { useCasinoGameDescription } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameDescription',
    props: {
        game: {},
        isOpen: {
            type: Boolean
        }
    },
    emits: [
        "toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isButtonVisible, gameDescription, buttonProperties } = useCasinoGameDescription(props);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-game-footer'])
            }, [
                _ctx.game.name ? _withDirectives((_openBlock(), _createElementBlock("h2", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['casino-game-footer__title'])
                }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_ABOUT_GAME')) + " " + _toDisplayString(_ctx.game.name), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'casino-game-footer-title'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-game-footer__description'])
                }, [
                    _ctx.game.statistics.length ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['casino-game-footer__table'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.game.statistics, (item, index)=>(_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass(_ctx.$style['casino-game-footer__table-item'])
                            }, [
                                _withDirectives((_openBlock(), _createElementBlock("span", {
                                    class: _normalizeClass(_ctx.$style['casino-game-footer__table-title'])
                                }, [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'casino-game-footer__table-title'
                                        }
                                    ]
                                ]),
                                _withDirectives((_openBlock(), _createElementBlock("span", {
                                    class: _normalizeClass(_ctx.$style['casino-game-footer__table-text'])
                                }, [
                                    _createTextVNode(_toDisplayString(item.value), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'casino-game-footer__table-text'
                                        }
                                    ]
                                ])
                            ], 2))), 128))
                    ], 2)) : _createCommentVNode("", true),
                    _withDirectives(_createVNode(VDynamicContent, {
                        content: _unref(gameDescription),
                        class: _normalizeClass(_ctx.$style['casino-game-footer__text'])
                    }, null, 8, [
                        "content",
                        "class"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: 'casino-game-description'
                            }
                        ]
                    ])
                ], 2),
                _unref(isButtonVisible) ? _withDirectives((_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 1
                }, _unref(buttonProperties), {
                    class: _ctx.$style['casino-game-footer__toggle-button'],
                    onClick: _cache[0] || (_cache[0] = ($event)=>emit('toggle'))
                }), null, 16, [
                    "class"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'casino-game-description__show-more-button'
                        }
                    ],
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'casino-game-description__show-more-button'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameDescription'
                ]
            ]);
        };
    }
});
