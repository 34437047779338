import { computed } from 'vue';
import { useSwiperToActiveScroll } from 'web/src/components/Swiper/VSwiper/composables';
export default function useCasinoCategoryList(props) {
    const selectedIndex = computed(()=>{
        const index = props.list.findIndex((item)=>item.isActive);
        return index > -1 ? index : 0;
    });
    const { swiper } = useSwiperToActiveScroll(selectedIndex);
    return {
        swiper
    };
}
