import { getIconName, IconName } from '@leon-hub/icons';
export default function useCasinoCategoryTabs() {
    function getIconProperties(item) {
        return {
            name: item.iconUrl || item.id.includes('id-') ? IconName.CROSS : getIconName({
                prefix: 'casino',
                name: item.id
            })
        };
    }
    return {
        getIconProperties
    };
}
