import { defineComponent as _defineComponent } from 'vue';
import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameRealMoneySwitch',
    props: {
        isChecked: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-game-real-money-switch'])
            }, [
                _createVNode(VSwitch, {
                    class: _normalizeClass(_ctx.$style['casino-game-real-money-switch__switch']),
                    label: _ctx.$t('WEB2_MONEY_PLAY'),
                    checked: _ctx.isChecked,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('click'), [
                        "prevent"
                    ]))
                }, null, 8, [
                    "class",
                    "label",
                    "checked"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameRealMoneySwitch'
                ]
            ]);
        };
    }
});
