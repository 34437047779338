import { computed, onMounted, ref } from 'vue';
import { useWindowResize } from '@leon-hub/browser-composables';
import prepareUrl from 'web/src/utils/prepareUrl';
import { isGameDescriptionAvailable } from 'web/src/modules/casino/submodules/game/utils';
const minWidth = 790;
const sideBarWidth = 280;
export default function useCasinoGameStart(props) {
    const wrapperStyles = computed(()=>{
        const styles = {};
        if (props.activeGame.images.background?.src) styles.backgroundImage = `url(${prepareUrl(props.activeGame.images.background.src)})`;
        return styles;
    });
    const isVpnGame = computed(()=>props.activeGame.isVpnAllowed);
    const hasGameDescription = computed(()=>isGameDescriptionAvailable(props.activeGame));
    const isSideBarOverGame = ref(false);
    function calculateSideBarOverGame() {
        isSideBarOverGame.value = window.innerWidth < minWidth + sideBarWidth;
    }
    onMounted(calculateSideBarOverGame);
    useWindowResize(calculateSideBarOverGame);
    let onLoaderMounted = ()=>{};
    let onIFrameLoaded = ()=>{};
    return {
        wrapperStyles,
        isSideBarOverGame,
        isVpnGame,
        hasGameDescription,
        onLoaderMounted,
        onIFrameLoaded
    };
}
