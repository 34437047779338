import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { useCasinoGroupsTopBarRouteComponent } from 'web/src/modules/casino/submodules/groups/views/CasinoGroupsTopBarRouteComponent/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGroupsTopBarRouteComponent',
    setup (__props) {
        const { badgeKind, counter } = useCasinoGroupsTopBarRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _ctx.$t('WEB2_PROVIDERS'),
                badge: _unref(counter),
                "badge-kind": _unref(badgeKind)
            }, null, 8, [
                "title",
                "badge",
                "badge-kind"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoGroupsTopBarRouteComponent'
                ]
            ]);
        };
    }
});
