import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { computed } from 'vue';
import { PanelLayout } from 'web/src/modules/casino/submodules/game/enums';
import CasinoGameBonusPanel from 'web/src/modules/casino/submodules/game/components/CasinoGameBonusPanel/CasinoGameBonusPanel.vue';
import WithdrawalContainer from 'web/src/modules/bonuses/components/WithdrawalContainer/WithdrawalContainer.vue';
import SCasinoGameBonusBar from 'web/src/modules/casino/submodules/game/components/SCasinoGameBonusBar/SCasinoGameBonusBar.vue';
import CasinoGameBonusBar from 'web/src/modules/casino/submodules/game/components/CasinoGameBonusBar/CasinoGameBonusBar.vue';
import { useCasinoGameBonusContainer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameBonusContainer',
    props: {
        layout: {
            default: PanelLayout.SLOTS_BAR
        },
        isClickable: {
            type: Boolean
        },
        isNarrowed: {
            type: Boolean
        }
    },
    emits: [
        "withdraw-bonus"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const bonusComponent = computed(()=>{
            switch(props.layout){
                case PanelLayout.SLOTS_PANEL:
                    return CasinoGameBonusPanel;
                case PanelLayout.SLOTS_BAR:
                default:
                    return CasinoGameBonusBar;
            }
        });
        const { isLoggedIn, bonus, withdrawMoney, getBonuses, onWithdrawBonus, redirectToBonusDetails } = useCasinoGameBonusContainer(emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isLoggedIn) && _unref(bonus) ? _withDirectives((_openBlock(), _createBlock(WithdrawalContainer, {
                key: 0,
                money: _unref(withdrawMoney),
                onModalClose: _unref(getBonuses)
            }, {
                default: _withCtx((param)=>{
                    let { withdrawBonus } = param;
                    return [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(bonusComponent.value), {
                            item: _unref(bonus),
                            "is-clickable": _ctx.isClickable,
                            "is-narrowed": _ctx.isNarrowed,
                            onWithdrawBonus: ($event)=>_unref(onWithdrawBonus)(withdrawBonus),
                            onClickBonusDetails: _unref(redirectToBonusDetails)
                        }, null, 40, [
                            "item",
                            "is-clickable",
                            "is-narrowed",
                            "onWithdrawBonus",
                            "onClickBonusDetails"
                        ]))
                    ];
                }),
                _: 1
            }, 8, [
                "money",
                "onModalClose"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoGameBonusContainer'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
