import { toRef, watch } from 'vue';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useDevtoolsDetectorStore } from 'web/src/modules/devtools-detector/store';
export default function useDevToolsDetector(onOpened) {
    const detectorStore = useDevtoolsDetectorStore();
    const { addListener, removeListener } = detectorStore;
    const isOpened = toRef(detectorStore, 'isOpened');
    let hasListener = false;
    function onActivated() {
        if (!hasListener) {
            addListener();
            hasListener = true;
        }
    }
    function onDeactivated() {
        if (hasListener) {
            removeListener();
            hasListener = false;
        }
    }
    onComponentActivated(onActivated);
    onComponentDeactivated(onDeactivated);
    watch(isOpened, (newValue)=>{
        if (newValue) onOpened();
    }, {
        immediate: true
    });
}
