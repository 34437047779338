export default function casinoExitRoutePageBeforeEnter(to, from, next) {
    try {
        const newUrl = to.query.url || '/';
        if (!window.top || window.top === window) {
            next({
                path: newUrl,
                replace: true
            });
            return;
        }
    } catch  {}
    //
    next();
}
