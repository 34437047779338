import { IconName } from '@leon-hub/icons';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
export default function getModalBonusProperties($t) {
    return {
        iconName: IconName.CHECK_OUTLINE,
        iconKind: JumbotronIconKind.SUCCESS,
        width: ModalWidth.SMALL,
        title: $t('WEB2_BONUS_OUTPUT_SUCCESS').value,
        buttons: [
            {
                label: $t('JSP_PCL_FBOT_CLOSE').value,
                action: DialogAction.MODAL_CLOSE
            }
        ]
    };
}
