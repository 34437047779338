import { computed, nextTick, onBeforeUnmount, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { StartCodeType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCasinoBetgamesStore } from 'web/src/modules/casino/submodules/betgames/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useDialogs from 'web/src/modules/dialogs/composables/useDialogs';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { AnalyticsModalType } from 'web/src/modules/dialogs/enums';
export default function useCasinoBetgamesRoutePage() {
    let modalId = '';
    const wrapper = ref();
    const isFrameLoaded = ref(false);
    const errorMessage = ref('');
    const casinoBetgamesStore = useCasinoBetgamesStore();
    const router = useRouter();
    const analytics = useAnalytics();
    const bus = useEventsBus();
    const { convertToBaseError } = useErrorsConverter();
    const { isLoggedIn } = useIsLoggedIn();
    const { closeDialog, showDialog } = useDialogs();
    const startGameData = toRef(casinoBetgamesStore, 'startGameData');
    const widgetsBlock = toRef(useSiteConfigStore(), 'widgetsBlock');
    const gameUrl = computed(()=>startGameData.value?.gameUrl);
    const iframeUrl = computed(()=>widgetsBlock.value?.iframeUrl);
    const isHtmlInjection = computed(()=>startGameData.value?.startCode === StartCodeType.HTML_INJECTION);
    onBeforeUnmount(()=>{
        casinoBetgamesStore.clearStartGameData();
        if (modalId) {
            closeDialog(modalId);
            modalId = '';
        }
    });
    watch(isLoggedIn, ()=>{
        fetchData();
    }, {
        immediate: true
    });
    function setFrameLoaded() {
        isFrameLoaded.value = true;
    }
    function onScrollTop(scrollTop) {
        bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
            scrollTop: scrollTop > 0 && wrapper.value ? scrollTop + wrapper.value.offsetTop : scrollTop
        });
    }
    function onClose() {
        router.back(CasinoRouteName.CASINO_LOBBY);
    }
    async function fetchData() {
        isFrameLoaded.value = false;
        errorMessage.value = '';
        casinoBetgamesStore.clearStartGameData();
        await nextTick();
        try {
            await casinoBetgamesStore.startGame();
        } catch (rawError) {
            const error = convertToBaseError(rawError);
            if (isGeneralApiError(error)) throw rawError;
            showError(error.message);
        }
    }
    function showError(message) {
        "1";
        analytics.push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
            [AnalyticsModalType.WARNING_EGS]: message
        });
        errorMessage.value = message;
    }
    return {
        wrapper,
        errorMessage,
        isFrameLoaded,
        isHtmlInjection,
        gameUrl,
        setFrameLoaded,
        iframeUrl,
        onClose,
        onScrollTop
    };
}
