import { computed, onBeforeMount, onBeforeUnmount, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { PromoActionType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { usePromotionDetailsCore } from 'web/src/modules/promotions/submodules/details/composables';
import { getPromoDetailsUrlForGame } from 'web/src/modules/casino/utils';
import { useCasinoActiveGame } from 'web/src/modules/casino/submodules/game/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCashbackStore } from 'web/src/modules/promotions/store';
const updateInterval = 120000;
export default function useCasinoGameLeaderBoard() {
    const { isLoggedIn } = useIsLoggedIn();
    const route = useRoute();
    const router = useRouter();
    const { activeGame } = useCasinoActiveGame();
    const isCashbackActive = toRef(useCashbackStore(), 'isCashbackActive');
    const { board, haveLeaderBoard, currentPromotion, currentPromoLeaderboardDate, actionUrl, getLeaderBoard, leaderBoard, loadPromotionDetails, setOpenedActiveUrl, isDoingActionButton, promotionButton, leaderBoardProperties, doButtonAction, scrollToLeaderBoard, goToCustomLink, loadMore, goToPlay } = usePromotionDetailsCore();
    const isBlockParticipationButton = computed(()=>currentPromotion.value?.isBlockParticipationButton);
    async function refreshLeaderBoard(pageSize) {
        if (actionUrl.value) await getLeaderBoard({
            actionUrl: actionUrl.value,
            id: actionUrl.value,
            pageSize,
            isUpdateRequest: true
        });
    }
    let updateLeaderBoardTimerId = 0;
    function stopUpdateLeaderBoard() {
        if (updateLeaderBoardTimerId) {
            Timer.clearTimeout(updateLeaderBoardTimerId);
            updateLeaderBoardTimerId = 0;
        }
    }
    function startUpdateLeaderBoard() {
        stopUpdateLeaderBoard();
        updateLeaderBoardTimerId = Timer.setTimeout(()=>{
            refreshLeaderBoard(leaderBoard.value.length).finally(startUpdateLeaderBoard);
        }, updateInterval);
    }
    async function initialDataRequest() {
        stopUpdateLeaderBoard();
        let url;
        if (route.params.actionUrl) url = route.params.actionUrl;
        else if (activeGame.value) url = await getPromoDetailsUrlForGame(activeGame.value);
        actionUrl.value = String(url);
        if (url) {
            isString(url);
            await Promise.all([
                loadPromotionDetails(url),
                getLeaderBoard({
                    actionUrl: url,
                    id: url
                })
            ]);
            startUpdateLeaderBoard();
        }
        if (!isCashbackActive.value) setOpenedActiveUrl(url || '');
    }
    function goToLoginPage() {
        router.push({
            name: RouteName.LOGIN
        });
    }
    function goToDetails() {
        if (currentPromotion.value && actionUrl.value) router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: PromoActionType.CBC,
                actionUrl: actionUrl.value
            }
        });
    }
    onBeforeMount(initialDataRequest);
    onBeforeUnmount(stopUpdateLeaderBoard);
    watch(isLoggedIn, initialDataRequest);
    return {
        board,
        isBlockParticipationButton,
        currentPromotion,
        currentPromoLeaderboardDate,
        promotionButton,
        isDoingActionButton,
        haveLeaderBoard,
        leaderBoardProperties,
        goToLoginPage,
        goToDetails,
        doButtonAction,
        scrollToLeaderBoard,
        goToCustomLink,
        loadMore,
        goToPlay
    };
}
