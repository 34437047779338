import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, withModifiers as _withModifiers } from "vue";
import { ref, onMounted } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import { useWindowResize } from '@leon-hub/browser-composables';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import CasinoGameSideBarSwitcher from 'web/src/modules/casino/submodules/game/components/CasinoGameSideBarSwitcher/CasinoGameSideBarSwitcher.vue';
import EgsFavorite from 'web/src/modules/egs/components/EgsFavorite/EgsFavorite.vue';
import BonusPointsContainer from 'web/src/modules/bonuses/components/BonusPointsContainer/BonusPointsContainer.vue';
import CasinoGameRealMoneySwitch from 'web/src/modules/casino/submodules/game/components/CasinoGameRealMoneySwitch/CasinoGameRealMoneySwitch.vue';
import CasinoGameButton from 'web/src/modules/casino/submodules/game/components/CasinoGameButton/CasinoGameButton.vue';
import CasinoGameTime from 'web/src/modules/casino/submodules/game/views/CasinoGameTime/CasinoGameTime.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameControlBar',
    props: {
        title: {
            default: ''
        },
        isFavorite: {
            type: Boolean
        },
        isCheckedSwitch: {
            type: Boolean
        },
        isDemoEnabled: {
            type: Boolean
        },
        isChatEnabled: {
            type: Boolean
        },
        closeHref: {},
        isLoyaltySwitcherEnabled: {
            type: Boolean
        },
        isOverGame: {
            type: Boolean
        },
        isSideBarOpened: {
            type: Boolean
        }
    },
    emits: [
        "click-chat",
        "fullscreen",
        "close",
        "click-switch",
        "side-bar-toggle",
        "favorite",
        "restart-game"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const container = ref();
        const isShort = ref(false);
        function setBonusPointsWidth() {
            const containerWidth = container.value?.offsetWidth || 0;
            const breakPointWidth = props.isDemoEnabled ? 900 : 800;
            isShort.value = containerWidth < breakPointWidth;
        }
        onMounted(setBonusPointsWidth);
        useWindowResize(setBonusPointsWidth);
        function onClickSwitch() {
            emit('click-switch', !props.isCheckedSwitch);
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "container",
                ref: container,
                class: _normalizeClass({
                    [_ctx.$style['casino-control-bar']]: true,
                    [_ctx.$style['casino-control-bar--sidebar-opened']]: _ctx.isSideBarOpened && !_ctx.isOverGame
                })
            }, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(_ctx.$style['casino-control-bar__title'])
                }, [
                    (_openBlock(), _createBlock(CasinoGameTime, {
                        key: 0
                    })),
                    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'casino-control-bar__title'
                        }
                    ]
                ]),
                _createElementVNode("div", null, [
                    (_openBlock(), _createBlock(BonusPointsContainer, {
                        key: 0,
                        "is-small": "",
                        "is-short": isShort.value
                    }, null, 8, [
                        "is-short"
                    ]))
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-control-bar__controls'])
                }, [
                    _createCommentVNode("", true),
                    _ctx.isDemoEnabled ? (_openBlock(), _createBlock(CasinoGameRealMoneySwitch, {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['casino-control-bar__switch']),
                        "is-checked": _ctx.isCheckedSwitch,
                        onClick: onClickSwitch
                    }, null, 8, [
                        "class",
                        "is-checked"
                    ])) : _createCommentVNode("", true),
                    (_openBlock(), _createElementBlock(_Fragment, {
                        key: 3
                    }, [
                        _createVNode(EgsFavorite, {
                            "is-favorite": _ctx.isFavorite,
                            onClick: _cache[4] || (_cache[4] = ($event)=>emit('favorite'))
                        }, null, 8, [
                            "is-favorite"
                        ]),
                        _ctx.isChatEnabled ? (_openBlock(), _createBlock(VButton, {
                            key: 0,
                            "icon-name": _unref(IconName).CHAT,
                            kind: _unref(ButtonKind).TRANSPARENT,
                            "icon-size": _unref(IconSize).SIZE_24,
                            onClick: _cache[5] || (_cache[5] = _withModifiers(($event)=>emit('click-chat'), [
                                "prevent"
                            ]))
                        }, null, 8, [
                            "icon-name",
                            "kind",
                            "icon-size"
                        ])) : _createCommentVNode("", true),
                        _createVNode(VButton, {
                            "icon-name": _unref(IconName).FULLSCREEN,
                            kind: _unref(ButtonKind).TRANSPARENT,
                            "icon-size": _unref(IconSize).SIZE_24,
                            onClick: _cache[6] || (_cache[6] = _withModifiers(($event)=>emit('fullscreen'), [
                                "prevent"
                            ]))
                        }, null, 8, [
                            "icon-name",
                            "kind",
                            "icon-size"
                        ]),
                        _createVNode(VButton, {
                            "icon-name": _unref(IconName).CROSS,
                            kind: _unref(ButtonKind).TRANSPARENT,
                            "icon-size": _unref(IconSize).SIZE_24,
                            tag: _unref(Tag).A,
                            href: _ctx.closeHref,
                            onClick: _cache[7] || (_cache[7] = _withModifiers(($event)=>emit('close'), [
                                "prevent"
                            ]))
                        }, null, 8, [
                            "icon-name",
                            "kind",
                            "icon-size",
                            "tag",
                            "href"
                        ]),
                        _ctx.isLoyaltySwitcherEnabled ? (_openBlock(), _createBlock(CasinoGameSideBarSwitcher, {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['casino-control-bar__sidebar-switcher']),
                            "is-side-bar-opened": _ctx.isSideBarOpened,
                            onClick: _cache[8] || (_cache[8] = ($event)=>emit('side-bar-toggle'))
                        }, null, 8, [
                            "class",
                            "is-side-bar-opened"
                        ])) : _createCommentVNode("", true)
                    ], 64))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameControlBar'
                ]
            ]);
        };
    }
});
