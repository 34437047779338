import { useRoute } from 'vue-router';
import { computed, toRef } from 'vue';
import { LobbyGridType, LobbyItemType, LobbyItemWidget, LobbyTitleType, lobbyTypeLiveCasino } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getLobbyCategoryUrlFromRoute, getLobbyGroupUrlFromRoute, isFavoriteEgsRoute, isLiveEgsRoute } from 'web/src/modules/egs/utils';
import { AllGamesGroupUrl } from 'web/src/modules/egs/constants';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { TopWinnersGamesGroupUrl } from 'web/src/modules/casino/constants';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import { useEgsFavoriteGamesStore } from 'web/src/modules/egs/submodules/favorite-games/store';
import { useCasinoAnalyticsStore } from 'web/src/modules/casino/submodules/analytics';
import { useCasinoLobby } from 'web/src/modules/casino/submodules/lobby/composables';
export default function useCasinoLobbyPage() {
    const route = useRoute();
    const { $translate } = useI18n();
    const egsSkeletons = toRef(useSiteConfigStore(), 'egsSkeletons');
    const { lobbyType, hasGroup, groupId, categoryId, groupName, group, category, isLoadingCategories, isLoggedIn, isLoadingLobby, groupBlockType, filteredGroupId, onClickLoyaltyProgram, onClickSearch, onClickGroup, onClickHelp } = useCasinoLobby();
    const { getLobbyItems } = useLobbyStore();
    const { setMetricsData, sendOpenGameLobbyMetrika } = useCasinoAnalyticsStore();
    const { removeFavoriteGames } = useEgsFavoriteGamesStore();
    const groupUrl = computed(()=>group.value?.url);
    const lobbyItems = computed(()=>getLobbyItems(lobbyType.value, filteredGroupId.value, categoryId.value).value);
    const hasNoGames = computed(()=>!isLoadingLobby.value && !lobbyItems.value.length);
    const isFavorite = computed(()=>isFavoriteEgsRoute(route));
    const isAllTab = computed(()=>!route.params.groupUrl && !route.params.categoryUrl && !isFavorite.value);
    const isTopWinner = computed(()=>route.params.categoryUrl === TopWinnersGamesGroupUrl);
    const skeletons = computed(()=>{
        if (isTopWinner.value) return [
            {
                typeId: LobbyItemType.TOP_WINNERS,
                gridType: LobbyGridType.DEFAULT,
                widgetId: LobbyItemWidget.LIST,
                titleType: LobbyTitleType.DEFAULT,
                swiperRowsNumber: 1,
                isSwiperBlock: false
            }
        ];
        if (!egsSkeletons.value) return [];
        const isLiveRoute = isLiveEgsRoute(route);
        if (isFavorite.value) return isLiveRoute ? egsSkeletons.value.liveMyTab : egsSkeletons.value.slotsMyTab;
        if (getLobbyCategoryUrlFromRoute(route)) return [
            {
                typeId: LobbyItemType.GAMES_CATEGORY,
                gridType: LobbyGridType.DEFAULT,
                widgetId: LobbyItemWidget.LIST,
                titleType: LobbyTitleType.DEFAULT,
                swiperRowsNumber: 1,
                isSwiperBlock: false
            }
        ];
        const routeGroupUrl = getLobbyGroupUrlFromRoute(route);
        if (routeGroupUrl && routeGroupUrl !== AllGamesGroupUrl) return isLiveRoute ? egsSkeletons.value.liveProvider : egsSkeletons.value.slotsProvider;
        return isLiveRoute ? egsSkeletons.value.liveAllTab : egsSkeletons.value.slotsAllTab;
    });
    const metaParameters = computed(()=>({
            lobbyType: lobbyType.value,
            groupName: groupName.value,
            group: group.value?.name || null,
            metaDescription: group.value?.metaDescription || null,
            category: category.value?.name || null,
            isFavorite: isFavorite.value,
            hasNoGames: hasNoGames.value
        }));
    function onPlay(game, isDemo, lobbyItem) {
        if (!isDemo && isLoggedIn.value) {
            const metricsData = {
                lobbyType: lobbyType.value === lobbyTypeLiveCasino ? 'live' : 'slots',
                groupFilterName: group.value?.name || $translate('WEB2_ALL_PROVIDERS').value,
                categoryFilterName: category.value?.name || $translate('WEB2_ALL_GAMES').value,
                section: lobbyItem?.name || '',
                vendorName: game?.group?.name || '',
                gameName: game?.name || ''
            };
            setMetricsData(metricsData);
            "1";
            sendOpenGameLobbyMetrika();
        }
    }
    return {
        isFavorite,
        isLoadingCategories,
        hasGroup,
        groupName,
        isAllTab,
        groupUrl,
        groupId,
        categoryId,
        lobbyType,
        isLoadingLobby,
        hasNoGames,
        skeletons,
        metaParameters,
        groupBlockType,
        onClickLoyaltyProgram,
        onClickSearch,
        onClickGroup,
        onClickHelp,
        onPlay,
        removeFavoriteGames,
        isLoggedIn
    };
}
