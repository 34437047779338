import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { CurrentModal } from 'web/src/modules/bonuses/components/WithdrawalContainer/enums';
import { useWithdrawalContainer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WithdrawalContainer',
    props: {
        money: {
            default: ''
        }
    },
    emits: [
        "modal-close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { modalSelector, currentModal, modalConfirmProperties, modalBonusProperties, onClickModal, onCloseModal, withdrawBonus } = useWithdrawalContainer(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "default", {
                    withdrawBonus: _unref(withdrawBonus)
                }),
                _createVNode(ModalPortal, {
                    selector: _unref(modalSelector)
                }, {
                    default: _withCtx(()=>[
                            _unref(currentModal) === _unref(CurrentModal).CONFIRM ? (_openBlock(), _createBlock(ConfirmModal, {
                                key: 0,
                                modal: _unref(modalConfirmProperties),
                                "is-alert": true,
                                onClose: _cache[0] || (_cache[0] = ($event)=>currentModal.value = _unref(CurrentModal).NONE),
                                onButtonClick: _unref(onClickModal)
                            }, null, 8, [
                                "modal",
                                "is-alert",
                                "onButtonClick"
                            ])) : _createCommentVNode("", true),
                            _unref(currentModal) === _unref(CurrentModal).BONUS ? (_openBlock(), _createBlock(ConfirmModal, {
                                key: 1,
                                modal: _unref(modalBonusProperties),
                                "is-alert": true,
                                onClose: _unref(onCloseModal),
                                onButtonClick: _unref(onClickModal)
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['bonus-modal__money'])
                                        }, _toDisplayString(_ctx.money), 3)
                                    ]),
                                _: 1
                            }, 8, [
                                "modal",
                                "is-alert",
                                "onClose",
                                "onButtonClick"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "selector"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'WithdrawalContainer'
                ]
            ]);
        };
    }
});
