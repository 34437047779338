import { computed, watch } from 'vue';
import { egsBlockTypeGeo } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useI18n } from 'web/src/modules/i18n/composables';
import { AnalyticsModalType } from 'web/src/modules/dialogs/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useCasinoGameError(game, isDemoUnavailable, startGameError, reloadCounter) {
    const { $translate } = useI18n();
    const analytics = useAnalytics();
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const errorData = computed(()=>{
        if (!game.value) return;
        if (game.value.blockType === egsBlockTypeGeo || game.value.group.blockType === egsBlockTypeGeo) return {
            type: 'game-block',
            title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
            text: $translate('WEB2_EGS_GAME_BLOCKED').value,
            button: {
                action: 'close',
                text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value
            }
        };
        if (game.value.isUnavailable) return {
            type: 'unavailable',
            title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
            text: $translate('WEB2_GAME_UNAVAILABLE').value,
            button: {
                action: 'close',
                text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value
            }
        };
        if (isDemoUnavailable.value) return {
            type: 'demo',
            title: $translate('WEB2_CASINO_DEMO_UNAVAILABLE_HEADING').value,
            text: $translate('WEB2_CASINO_DEMO_UNAVAILABLE_TEXT').value,
            button: {
                action: 'login',
                text: $translate('WEB2_CASINO_DEMO_UNAVAILABLE_BTN').value
            }
        };
        if (!startGameError.value) return;
        if ('EGS_USER_IS_BLOCKED' === startGameError.value.code) return {
            type: 'game-block',
            title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
            text: startGameError.value.message,
            button: {
                action: 'close',
                text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value
            }
        };
        if ('RESTRICTED_COUNTRY' === startGameError.value.code) return {
            type: 'provider-block',
            title: $translate('WEB2_EGS_GAME_ERROR_PROVIDER_UNAVAILABLE_TTL').value,
            text: startGameError.value.message,
            button: {
                action: 'providers',
                text: $translate('WEB2_EGS_GAME_ERROR_ALL_PROVIDERS_BTN').value
            }
        };
        if ('GAME_NOT_FOUND' === startGameError.value.code) return {
            type: 'unavailable',
            title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
            text: startGameError.value.message,
            button: {
                action: 'close',
                text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value
            }
        };
        if ('BETS_SELF_EXCLUSION_ERROR' === startGameError.value.code) return {
            type: 'unavailable',
            title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
            text: startGameError.value.message,
            button: {
                action: 'close',
                text: $translate('WEB2_CLOSE').value
            }
        };
        const isReload = 0 === reloadCounter.value;
        return {
            type: 'unavailable',
            title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
            text: startGameError.value.message,
            button: {
                action: isReload ? 'refresh' : 'close',
                text: isReload ? $translate('WEB2_EGS_GAME_ERROR_REFRESH_BTN').value : $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value
            }
        };
    });
    watch(startGameError, (newValue)=>{
        if (newValue) analytics.push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
            [AnalyticsModalType.WARNING_EGS]: newValue.message
        });
    }, {
        deep: true,
        immediate: true
    });
    return {
        errorData
    };
}
