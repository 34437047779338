import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRoute, useRouter } from 'vue-router';
const __default__ = {
    name: 'CasinoExitRoutePage',
    // eslint-disable-next-line global-require
    beforeRouteEnter: require('./before-enter/CasinoExitRoutePageBeforeEnter').default
};
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    setup (__props) {
        const route = useRoute();
        const router = useRouter();
        const url = route.query.url || '/';
        if (!window.top || window.top === window) router.replace(url);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, null, 512)), [
                [
                    _directive_auto_id,
                    'CasinoExitRoutePage'
                ]
            ]);
        };
    }
});
