import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import CasinoSidebarBonusBlock from 'web/src/modules/casino/submodules/lobby/components/CasinoSidebarBonusBlock/CasinoSidebarBonusBlock.vue';
import CasinoSidebarFilterBlock from 'web/src/modules/casino/submodules/lobby/components/CasinoSidebarFilterBlock/CasinoSidebarFilterBlock.vue';
import CasinoSidebarNavigationBlock from 'web/src/modules/casino/submodules/lobby/components/CasinoSidebarNavigationBlock/CasinoSidebarNavigationBlock.vue';
import { useCasinoLobby, useCategoriesTabs } from 'web/src/modules/casino/submodules/lobby/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSidebarRouteComponent',
    setup (__props) {
        const { groupName, hasGroup, onClickSearch, onClickGroup, onClickHelp, isLoadingCategories, isLoggedIn, isLoadingLobby, isHelpMode, isEgsLoyaltyEnabled } = useCasinoLobby();
        const { categoriesTabs } = useCategoriesTabs();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-sidebar'])
            }, [
                _unref(isLoggedIn) && _unref(isEgsLoyaltyEnabled) ? (_openBlock(), _createBlock(CasinoSidebarBonusBlock, {
                    key: 0,
                    "is-loading": _unref(isLoadingLobby)
                }, null, 8, [
                    "is-loading"
                ])) : _createCommentVNode("", true),
                _createVNode(CasinoSidebarFilterBlock, {
                    "active-group-name": _unref(groupName),
                    "has-group-name": _unref(hasGroup),
                    class: _normalizeClass(_ctx.$style['casino-sidebar__filter']),
                    onClickGroups: _unref(onClickGroup),
                    onClickSearch: _unref(onClickSearch)
                }, null, 8, [
                    "active-group-name",
                    "has-group-name",
                    "class",
                    "onClickGroups",
                    "onClickSearch"
                ]),
                _createVNode(CasinoSidebarNavigationBlock, {
                    class: _normalizeClass(_ctx.$style['casino-sidebar__navigation']),
                    "is-loading": _unref(isLoadingCategories),
                    list: _unref(categoriesTabs)
                }, null, 8, [
                    "class",
                    "is-loading",
                    "list"
                ]),
                _unref(isHelpMode) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([
                        _ctx.$style['casino-sidebar__search'],
                        _ctx.$style['casino-sidebar__help']
                    ])
                }, [
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).TRANSPARENT,
                        label: _ctx.$t('WEB2_CASINO_SIDEBAR_HELP'),
                        "full-width": "",
                        onClick: _unref(onClickHelp)
                    }, null, 8, [
                        "kind",
                        "label",
                        "onClick"
                    ])
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoSidebarRouteComponent'
                ]
            ]);
        };
    }
});
