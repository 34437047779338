import { computed } from 'vue';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { getImageOrIcon } from 'web/src/modules/icons';
export default function getModalConfirmProperties(props, $t) {
    const params = computed(()=>({
            AMOUNT: props.money || ''
        }));
    const { iconName, image } = getImageOrIcon({
        alertIcon: AlertIconName.Bonus,
        icon: IconName.INFO_OUTLINE
    });
    return {
        iconName,
        image,
        width: ModalWidth.SMALL,
        title: $t('WEB2_BONUS_OUTPUT').value,
        confirmMessage: $t('WEB2_BONUS_OUTPUT_QUESTION', params).value,
        buttons: [
            {
                action: DialogAction.CONFIRM,
                kind: ButtonKind.PRIMARY,
                label: $t('WEB2_MODAL_CONFIRM').value
            }
        ]
    };
}
