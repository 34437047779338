import RouteName from '@leon-hub/routing-config';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { HomePageType } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useEgsBasePrefetch from 'web/src/modules/egs/composables/useEgsBasePrefetch';
let CasinoLobbyPagePrefetch = class CasinoLobbyPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    shouldRedirectToHome(route) {
        const { homePageType } = useSiteConfigStore();
        return (homePageType === HomePageType.LIVE_GAMES && route.name === CasinoRouteName.CASINO_LIVE_LOBBY || homePageType === HomePageType.SLOTS && route.name === CasinoRouteName.CASINO_LOBBY) && !route.params.groupUrl && !route.params.categoryUrl;
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async prefetch(router, to, from, next) {
        if (this.shouldRedirectToHome(to)) {
            next(router.resolve301location({
                name: RouteName.HOME
            }));
            return;
        }
        const input = await useEgsBasePrefetch().prefetch(to, next);
        if (!input) return;
        next();
    }
};
export { CasinoLobbyPagePrefetch as default };
