import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = {
    key: 1
};
import PromotionDetailsButton from 'web/src/modules/promotions/components/PromotionButton/PromotionButton.vue';
import PromotionDetailsHeader from 'web/src/modules/promotions/components/PromotionDetailsHeader/PromotionDetailsHeader.vue';
import LeaderBoardWrapper from 'web/src/modules/promotions/components/LeaderBoardWrapper/LeaderBoardWrapper.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import LeaderBoard from 'web/src/modules/promotions/components/LeaderBoard/LeaderBoard.vue';
import { useCasinoGameLeaderBoard } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameLeaderBoard',
    setup (__props) {
        const { board, isBlockParticipationButton, currentPromotion, currentPromoLeaderboardDate, promotionButton, isDoingActionButton, haveLeaderBoard, leaderBoardProperties, doButtonAction, scrollToLeaderBoard, goToLoginPage, goToDetails, goToCustomLink, loadMore, goToPlay } = useCasinoGameLeaderBoard();
        return (_ctx, _cache)=>_unref(currentPromotion) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-promotions'])
                }, [
                    _createVNode(PromotionDetailsHeader, {
                        class: _normalizeClass(_ctx.$style['promotion__title-wrapper']),
                        promotion: _unref(currentPromotion)
                    }, null, 8, [
                        "class",
                        "promotion"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-promotions-button'])
                    }, [
                        _unref(promotionButton) ? (_openBlock(), _createBlock(PromotionDetailsButton, {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['casino-promotions-button__item']]: true,
                                [_ctx.$style['casino-promotions-button__item--primary']]: true
                            }),
                            "button-item": _unref(promotionButton),
                            "is-disabled": _unref(isBlockParticipationButton),
                            "button-is-loading": _unref(isDoingActionButton),
                            onParticipate: _unref(doButtonAction),
                            onLeaderboard: _unref(scrollToLeaderBoard),
                            onExchange: _unref(doButtonAction),
                            onLogin: _unref(goToLoginPage),
                            onCustom: _unref(goToCustomLink),
                            onPlay: _unref(goToPlay)
                        }, null, 8, [
                            "class",
                            "button-item",
                            "is-disabled",
                            "button-is-loading",
                            "onParticipate",
                            "onLeaderboard",
                            "onExchange",
                            "onLogin",
                            "onCustom",
                            "onPlay"
                        ])) : _createCommentVNode("", true),
                        _createVNode(VButton, {
                            class: _normalizeClass({
                                [_ctx.$style['casino-promotions-button__item']]: true,
                                [_ctx.$style['casino-promotions-button__item--secondary']]: true
                            }),
                            kind: _unref(ButtonKind).SECONDARY,
                            label: _ctx.$t('WEB2_CBC_PROMO_MORE'),
                            onClick: _unref(goToDetails)
                        }, null, 8, [
                            "class",
                            "kind",
                            "label",
                            "onClick"
                        ])
                    ], 2)
                ], 2),
                _unref(haveLeaderBoard) ? (_openBlock(), _createBlock(LeaderBoardWrapper, {
                    key: 0,
                    ref_key: "board",
                    ref: board,
                    subheader: _unref(currentPromoLeaderboardDate)
                }, {
                    default: _withCtx(()=>[
                            _unref(leaderBoardProperties) ? (_openBlock(), _createBlock(LeaderBoard, _mergeProps({
                                key: 0,
                                class: {
                                    [_ctx.$style['promotion__leader-board']]: true,
                                    [_ctx.$style['promotion__leader-board--default']]: true
                                }
                            }, _unref(leaderBoardProperties), {
                                onLoadMore: _unref(loadMore)
                            }), null, 16, [
                                "class",
                                "onLoadMore"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "subheader"
                ])) : _createCommentVNode("", true)
            ])) : (_openBlock(), _createElementBlock("div", _hoisted_2));
    }
});
