import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { PostMessageEvent } from '@leon-hub/postmessage-bus';
export const BetgamesWidgetPostMessageBusInitiator = 'BetgamesWidget';
let BetgamesWidgetPostMessageEvent = class BetgamesWidgetPostMessageEvent extends PostMessageEvent {
};
_define_property(BetgamesWidgetPostMessageEvent, "init", new PostMessageEvent('betgames-widget-init'));
_define_property(BetgamesWidgetPostMessageEvent, "stateChanged", new PostMessageEvent('betgames-widget-state-changed'));
_define_property(BetgamesWidgetPostMessageEvent, "changeHeight", new PostMessageEvent('betgames-widget-change-height'));
_define_property(BetgamesWidgetPostMessageEvent, "scrollTop", new PostMessageEvent('betgames-widget-scroll-top'));
export { BetgamesWidgetPostMessageEvent as default };
