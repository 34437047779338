import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromotionsCashbackWidget',
    props: {
        timestamp: {},
        cashbackAmount: {}
    },
    emits: [
        "send"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const formatMoney = useFormatMoney();
        const cashbackAmountFormatted = computed(()=>formatMoney(props.cashbackAmount));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['promotion-cashback-widget'])
            }, [
                _createElementVNode("div", null, [
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['promotion-cashback-widget__label'])
                    }, _toDisplayString(_ctx.$t('WEB2_CURRENT_CASHBACK')), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['promotion-cashback-widget__amount'])
                    }, _toDisplayString(cashbackAmountFormatted.value), 3)
                ]),
                _createVNode(PromotionCountdown, {
                    "hidden-title": "",
                    "is-alt-time-name": "",
                    "is-cashback": "",
                    timestamp: _ctx.timestamp,
                    onSend: _cache[0] || (_cache[0] = ($event)=>emit('send'))
                }, null, 8, [
                    "timestamp"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PromotionsCashbackWidget'
                ]
            ]);
        };
    }
});
