export var CasinoGameCloseMethod;
var CasinoGameCloseMethod1;
(CasinoGameCloseMethod1 = CasinoGameCloseMethod || (CasinoGameCloseMethod = {}))["SWIPE"] = "swipe", CasinoGameCloseMethod1["ICON"] = "icon";
export var EgsPostMessageEvent;
var EgsPostMessageEvent1;
(EgsPostMessageEvent1 = EgsPostMessageEvent || (EgsPostMessageEvent = {}))["gameExit"] = "game:exit", EgsPostMessageEvent1["gameError"] = "game:error", EgsPostMessageEvent1["backToLobby"] = "backToLobby";
export var PanelLayout;
var PanelLayout1;
(PanelLayout1 = PanelLayout || (PanelLayout = {}))["PANEL"] = "panel", PanelLayout1["SLOTS_PANEL"] = "slots-panel", PanelLayout1["SLOTS_BAR"] = "slots-bar";
export var DigitainProviderPostMessageEvent;
var DigitainProviderPostMessageEvent1;
(DigitainProviderPostMessageEvent1 = DigitainProviderPostMessageEvent || (DigitainProviderPostMessageEvent = {}))["backToHome"] = "rgs-backToHome", DigitainProviderPostMessageEvent1["deposit"] = "rgs-deposit";
