import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import CasinoLoyaltyProgramProgress from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramProgress/CasinoLoyaltyProgramProgress.vue';
import { CircleColor } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { useCasinoSidebarLoyaltyStatus } from 'web/src/modules/casino/submodules/lobby/views/CasinoSidebarRouteComponent/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoSidebarBonusBlock',
    props: {
        isLoading: {
            type: Boolean
        }
    },
    setup (__props) {
        const arrowIcon = {
            name: IconName.EXPAND_RIGHT,
            size: IconSize.SIZE_16
        };
        const circleColor = CircleColor.PURPLE;
        const { loyaltyProgress, loyaltyCard, pointsText } = useCasinoSidebarLoyaltyStatus();
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block'])
            }, [
                _ctx.isLoading ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__loader'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__loader-avatar'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass([
                                "skeleton-loading",
                                _ctx.$style['casino-sidebar-bonus-block__loader-circle']
                            ])
                        }, null, 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__loader-text'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass([
                                "skeleton-loading",
                                _ctx.$style['casino-sidebar-bonus-block__loader-line-1']
                            ])
                        }, null, 2),
                        _createElementVNode("span", {
                            class: _normalizeClass([
                                "skeleton-loading",
                                _ctx.$style['casino-sidebar-bonus-block__loader-line-2']
                            ])
                        }, null, 2)
                    ], 2)
                ], 2)) : (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__body']),
                    to: _ctx.$to(_unref(CasinoRouteName).CASINO_LOYALTY_PROGRAM)
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__left'])
                            }, [
                                _createVNode(CasinoLoyaltyProgramProgress, _mergeProps({
                                    class: _ctx.$style['casino-sidebar-bonus-block__progress'],
                                    "circle-color": _unref(circleColor)
                                }, _unref(loyaltyProgress), {
                                    "image-src": require('web/src/assets/images/beginner.svg')
                                }), null, 16, [
                                    "class",
                                    "circle-color",
                                    "image-src"
                                ]),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__info'])
                                }, [
                                    _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__title'])
                                    }, _toDisplayString(_unref(loyaltyCard).levelTitle), 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['casino-sidebar-bonus-block__label'])
                                    }, _toDisplayString(_unref(pointsText)), 3)
                                ], 2)
                            ], 2),
                            _createElementVNode("div", null, [
                                _createVNode(_unref(VIcon), _mergeProps({
                                    class: _ctx.$style['casino-sidebar-bonus-block__icon']
                                }, arrowIcon), null, 16, [
                                    "class"
                                ])
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "to"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoSidebarBonusBlock'
                ]
            ]);
        };
    }
});
