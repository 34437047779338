import { computed, toRef } from 'vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
export default function useCasinoGroupsTopBarRouteComponent() {
    const badgeKind = BadgeKind.DEFAULT;
    const currentGroups = toRef(useEgsGroupsStore(), 'currentGroups');
    const counter = computed(()=>currentGroups.value.length > 0 ? String(currentGroups.value.length) : '');
    return {
        badgeKind,
        counter
    };
}
