import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from 'vue';
import { Timer } from '@leon-hub/utils';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameTime',
    setup (__props) {
        const { lang } = useI18nLocale();
        function getTime() {
            try {
                return new Date().toLocaleTimeString(lang.value, {
                    hour: 'numeric',
                    minute: 'numeric'
                });
            } catch  {
                return '';
            }
        }
        const time = ref(getTime());
        let timeout = 0;
        onComponentActivated(()=>{
            timeout = Timer.setInterval(()=>{
                time.value = getTime();
            }, 1000);
        });
        onComponentDeactivated(()=>{
            if (timeout) {
                Timer.clearInterval(timeout);
                timeout = 0;
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return time.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['casino-game-time'])
            }, [
                _createTextVNode(_toDisplayString(time.value), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameTime'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
