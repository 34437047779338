import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import CasinoProviderSelect from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/CasinoProviderSelect.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import CasinoCategoryTabs from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryTabs/CasinoCategoryTabs.vue';
import { useCasinoLobbyNavigation } from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyNavigation/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLobbyNavigation',
    props: {
        activeGroupName: {},
        categoriesTabs: {},
        hasGroupName: {
            type: Boolean
        },
        isCategoriesLoading: {
            type: Boolean
        }
    },
    emits: [
        "click-groups",
        "click-search"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { searchButtonProperties, emitClickGroups, emitClickSearch } = useCasinoLobbyNavigation(emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-lobby-navigation'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-lobby-navigation__group'])
                }, [
                    _withDirectives(_createVNode(VButton, _mergeProps(_unref(searchButtonProperties), {
                        class: _ctx.$style['casino-lobby-navigation__search'],
                        onClick: _unref(emitClickSearch)
                    }), null, 16, [
                        "class",
                        "onClick"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: 'casino-button-search'
                            }
                        ]
                    ]),
                    _createVNode(CasinoProviderSelect, {
                        title: _ctx.activeGroupName,
                        "has-selected-group": _ctx.hasGroupName,
                        class: _normalizeClass(_ctx.$style['casino-lobby-navigation__provider']),
                        onClick: _unref(emitClickGroups)
                    }, null, 8, [
                        "title",
                        "has-selected-group",
                        "class",
                        "onClick"
                    ])
                ], 2),
                _createVNode(CasinoCategoryTabs, {
                    list: _ctx.categoriesTabs,
                    "is-loading": _ctx.isCategoriesLoading
                }, null, 8, [
                    "list",
                    "is-loading"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLobbyNavigation'
                ]
            ]);
        };
    }
});
