import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { isString } from '@leon-hub/guards';
import { ApiError } from '@leon-hub/api';
import { EgsRouterParametersHandler, isDemoMethod } from 'web/src/modules/egs/submodules/navigation/utils';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useCasinoGameAnalytics } from 'web/src/modules/casino/composables';
let CasinoGamePagePrefetch = class CasinoGamePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const gamesStore = useCasinoGameStore();
        const activeGame = toRef(gamesStore, 'activeGame');
        const { groupUrl, gameUrl } = to.params;
        isString(groupUrl);
        isString(gameUrl);
        const gameStartMethod = EgsRouterParametersHandler.getSelectedGameMethod();
        EgsRouterParametersHandler.setSelectedGameMethod(void 0);
        try {
            await gamesStore.setActiveUrl(groupUrl, gameUrl);
        } catch (error) {
            if (!(error instanceof ApiError) || 'EGS_GAME_UNAVAILABLE' !== error.code.toString()) throw error;
        }
        const { isLoggedIn } = useIsLoggedIn();
        if (!activeGame.value) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        if (from.fullPath !== to.fullPath) gamesStore.setDemoStartMode(isDemoMethod(activeGame.value, isLoggedIn.value, gameStartMethod));
        "1";
        useCasinoGameAnalytics().sendOpenGameMetrika(gamesStore.isDemo);
        next();
    }
};
export { CasinoGamePagePrefetch as default };
