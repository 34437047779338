import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { IconName, IconSize } from '@leon-hub/icons';
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameSideBarAnonymous',
    props: {
        isOverGame: {
            type: Boolean
        }
    },
    emits: [
        "click-close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const router = useRouter();
        const imageDefaultSrc = require('web/src/assets/images/beginner.png');
        function onClose() {
            emit('click-close');
        }
        function onClick() {
            router.push({
                name: RouteName.REGISTRATION
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-game-sidebar-anonymous'])
            }, [
                _ctx.isOverGame ? (_openBlock(), _createBlock(VButton, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-anonymous__button-close']),
                    "icon-name": _unref(IconName).CROSS,
                    "icon-size": _unref(IconSize).SIZE_24,
                    kind: _unref(ButtonKind).TRANSPARENT,
                    onClick: onClose
                }, null, 8, [
                    "class",
                    "icon-name",
                    "icon-size",
                    "kind"
                ])) : _createCommentVNode("", true),
                _createVNode(CasinoLoyaltyProgramIcon, {
                    size: _unref(CasinoLoyaltyProgramIconSizes).SIZE_180,
                    "image-src": _unref(imageDefaultSrc),
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-anonymous__icon'])
                }, null, 8, [
                    "size",
                    "image-src",
                    "class"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-anonymous__heading'])
                }, _toDisplayString(_ctx.$t('WEB2_GAME_SIDEBAR_UNLOGGED_TITLE')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-anonymous__text'])
                }, _toDisplayString(_ctx.$t('WEB2_GAME_SIDEBAR_UNLOGGED_TEXT')), 3),
                _createVNode(VButton, {
                    label: _ctx.$t('WEB2_SIGNIN_AND_CLAIM'),
                    height: _unref(ButtonHeight).SMALL,
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-anonymous__button']),
                    onClick: _withModifiers(onClick, [
                        "prevent"
                    ])
                }, null, 8, [
                    "label",
                    "height",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameSideBarAnonymous'
                ]
            ]);
        };
    }
});
