import { toRef, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCashbackStore } from 'web/src/modules/promotions/store';
export default function useCasinoGameCashbackContainer() {
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const isWeb2BonusCashbackWidgetEnabled = toRef(useSiteConfigStore(), 'isWeb2BonusCashbackWidgetEnabled');
    const cashbackStore = useCashbackStore();
    const cashbackAmount = toRef(cashbackStore, 'cashbackAmount');
    const timestamp = toRef(cashbackStore, 'timestampEndDate');
    const isCurrentCashback = toRef(cashbackStore, 'isCurrentCashback');
    const cashbackPromotionUrl = toRef(cashbackStore, 'cashbackPromotionUrl');
    const cashbackPromotionCategoryId = toRef(cashbackStore, 'cashbackPromotionCategoryId');
    const isVisible = computed(()=>isLoggedIn.value && isWeb2BonusCashbackWidgetEnabled.value && isCurrentCashback.value);
    function onBalanceClick() {
        if (cashbackPromotionCategoryId.value && cashbackPromotionUrl.value) router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: cashbackPromotionCategoryId.value,
                actionUrl: cashbackPromotionUrl.value
            }
        });
    }
    function hideWidget() {
        cashbackStore.setCashbackActive(false);
        cashbackStore.getCashback();
    }
    onBeforeMount(()=>{
        if (isLoggedIn.value && isWeb2BonusCashbackWidgetEnabled.value) cashbackStore.getCashback();
    });
    return {
        isVisible,
        cashbackAmount,
        timestamp,
        onBalanceClick,
        hideWidget
    };
}
