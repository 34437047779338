import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import CasinoBetgamesIframeWidget from // eslint-disable-next-line max-len
'web/src/modules/casino/submodules/betgames/components/CasinoBetgamesIframeWidget/CasinoBetgamesIframeWidget.vue';
import CasinoGameIframeError from 'web/src/modules/casino/components/CasinoGameIframeError/CasinoGameIframeError.vue';
import CasinoGameLoader from 'web/src/modules/casino/components/CasinoGameLoader/CasinoGameLoader.vue';
import useCasinoBetgamesRoutePage from './composables/useCasinoBetgamesRoutePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoBetgamesRoutePage',
    setup (__props) {
        const { wrapper, errorMessage, isFrameLoaded, isHtmlInjection, gameUrl, setFrameLoaded, iframeUrl, onClose, onScrollTop } = useCasinoBetgamesRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass(_ctx.$style['betgames'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['betgames__wrapper'])
                }, [
                    _unref(errorMessage) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['betgames__iframe-error'])
                    }, [
                        _createVNode(CasinoGameIframeError, {
                            type: "unavailable",
                            text: _unref(errorMessage),
                            button: {
                                text: _ctx.$t('WEB2_CLOSE'),
                                action: 'close'
                            },
                            onClick: _unref(onClose)
                        }, null, 8, [
                            "text",
                            "button",
                            "onClick"
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _unref(isFrameLoaded) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['betgames__loader'])
                    }, [
                        _createVNode(CasinoGameLoader, {
                            "is-dark": ""
                        })
                    ], 2)),
                    !_unref(isHtmlInjection) && _unref(gameUrl) ? (_openBlock(), _createBlock(VIframe, {
                        key: 2,
                        name: "betgames-name",
                        src: _unref(gameUrl),
                        allow: "autoplay; fullscreen; encrypted-media;",
                        "full-width": "",
                        "hide-title": "",
                        class: _normalizeClass(_ctx.$style['betgames__iframe']),
                        onLoad: _unref(setFrameLoaded)
                    }, null, 8, [
                        "src",
                        "class",
                        "onLoad"
                    ])) : _unref(isHtmlInjection) && _unref(gameUrl) ? (_openBlock(), _createBlock(CasinoBetgamesIframeWidget, {
                        key: 3,
                        "game-html": _unref(gameUrl),
                        "iframe-url": _unref(iframeUrl),
                        class: _normalizeClass(_ctx.$style['betgames__iframe']),
                        onLoad: _unref(setFrameLoaded),
                        onScrollTop: _unref(onScrollTop)
                    }, null, 8, [
                        "game-html",
                        "iframe-url",
                        "class",
                        "onLoad",
                        "onScrollTop"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoBetgamesRoutePage'
                ]
            ]);
        };
    }
});
