import { computed } from 'vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCasinoGameDescription(props) {
    const { $translate } = useI18n();
    const isButtonVisible = computed(()=>false);
    const gameDescription = computed(()=>{
        "1";
        return props.game.description;
    });
    const buttonProperties = computed(()=>({
            isUppercase: false,
            kind: ButtonKind.BASE,
            height: ButtonHeight.MEDIUM,
            label: props.isOpen ? $translate('WEB2_LABEL_HIDE').value : $translate('WEB2_SHOW_MORE').value
        }));
    return {
        isButtonVisible,
        gameDescription,
        buttonProperties
    };
}
