import { computed, toRef } from 'vue';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';
export default function useCasinoSidebarLoyaltyStatus() {
    const casinoLoyaltyStore = useCasinoLoyaltyStore();
    const loyaltyProgress = toRef(casinoLoyaltyStore, 'levelGameProperties');
    const loyaltyPoints = toRef(casinoLoyaltyStore, 'currentPoints');
    const loyaltyCard = toRef(casinoLoyaltyStore, 'currentLevelCardProperties');
    toRef(casinoLoyaltyStore, 'secondaryText');
    const pointsText = computed(()=>`${loyaltyPoints.value} L`);
    return {
        loyaltyProgress,
        loyaltyPoints,
        loyaltyCard,
        pointsText
    };
}
