import { PresetName } from 'web/src/modules/dialogs/enums';
export default function getModalErrorHandlerProperties(message, $t) {
    return {
        presetName: PresetName.ALERT_WARNING,
        options: {
            confirmMessage: message,
            title: $t('WEB2_DEPOSIT_ERROR').value
        }
    };
}
