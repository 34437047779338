import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default function useCasinoLobbyNavigation(emits) {
    const searchButtonProperties = {
        kind: ButtonKind.BASE,
        iconName: IconName.SEARCH,
        iconSize: IconSize.SIZE_24
    };
    function emitClickGroups() {
        emits('click-groups');
    }
    function emitClickSearch() {
        emits('click-search');
    }
    return {
        searchButtonProperties,
        emitClickGroups,
        emitClickSearch
    };
}
