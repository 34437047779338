import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { GameOpenMethod } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { isObject } from '@leon-hub/guards';
import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { logger } from '@leon-hub/logging';
import { CasinoRouteName } from '@leon-hub/routing-config-names';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useEgsFavoriteGamesStore } from 'web/src/modules/egs/submodules/favorite-games/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCasinoGameAnalytics } from 'web/src/modules/casino/composables';
import { isCasinoGameRouteName, isCasinoLobbyRouteName } from 'web/src/modules/casino/utils';
import { useAppFullscreenStore, useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useCasinoAnalyticsStore } from 'web/src/modules/casino/submodules/analytics';
import { mapActiveGameToAnalytics } from 'web/src/modules/casino/submodules/game/utils';
import { CasinoGameCloseMethod } from 'web/src/modules/casino/submodules/game/enums';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useCasinoActiveGame } from 'web/src/modules/casino/submodules/game/composables';
import IFrameTimeoutError from 'web/src/modules/errors/errors/IFrameTimeoutError';
import { isLiveEgsRoute } from 'web/src/modules/egs/utils';
import useOpenGameInNewWindow from './useOpenGameInNewWindow';
import useCasinoGameExitUrls from './useCasinoGameExitUrls';
import useCasinoGameStartPostMessage from './useCasinoGameStartPostMessage';
import useCasinoGameError from './useCasinoGameError';
function isFetchStartError(error) {
    return isObject(error) && !!error.code;
}
export default function useCasinoGameRoutePage() {
    const { $translate } = useI18n();
    const { isLoggedIn } = useIsLoggedIn();
    const router = useRouter();
    const route = useRoute();
    const analytics = useAnalytics();
    const bus = useEventsBus();
    const fullscreenStore = useAppFullscreenStore();
    const { setFullscreenMode } = fullscreenStore;
    const routerStore = useRouterStore();
    const previousRouteName = toRef(routerStore, 'previousRouteName');
    const gamesStore = useCasinoGameStore();
    const isGamePreviewMode = toRef(gamesStore, 'isGamePreviewMode');
    const startGameData = toRef(gamesStore, 'startGameData');
    const isDemoState = toRef(gamesStore, 'isDemo');
    const isStartGameLoadingState = toRef(gamesStore, 'isStartGameLoading');
    const isLoyaltySidebarOpened = toRef(gamesStore, 'isLoyaltySidebarOpened');
    const startGameAfterLogin = toRef(gamesStore, 'startGameAfterLogin');
    const isAppInFullscreenMode = toRef(fullscreenStore, 'isAppInFullscreenMode');
    const { backToLobbyLocation, closeUrl, getBankingUrl, getExitUrl } = useCasinoGameExitUrls();
    const { activeGame } = useCasinoActiveGame();
    const hideLimitModalOnGamePage = computed(()=>startGameData.value?.hideLimitModalOnGamePage);
    const showBonusMaxBetLimitPopup = computed(()=>startGameData.value?.showBonusMaxBetLimitPopup);
    const maxBetLimit = computed(()=>startGameData.value?.maxBetLimit);
    const freespinInfo = computed(()=>startGameData.value?.freeSpinInfo);
    const gameUrl = computed(()=>startGameData.value?.gameUrl);
    const gameStartCode = computed(()=>startGameData.value?.startCode);
    // eslint-disable-next-line max-len
    const showBonusReminder = computed(()=>!hideLimitModalOnGamePage.value && !!maxBetLimit.value && !freespinInfo.value && !!showBonusMaxBetLimitPopup.value);
    const isLoading = computed(()=>!activeGame.value || !gameUrl.value || isStartGameLoadingState.value);
    const isDemoUnavailable = computed(()=>!isDemoState.value && !isLoggedIn.value);
    const scgStore = useSiteConfigStore();
    const scgExternalGames = toRef(scgStore, 'externalGames');
    const isLoyaltySwitcherEnabled = toRef(scgStore, 'isEgsLoyaltyEnabled');
    const { toggleFavoriteGame } = useEgsFavoriteGamesStore();
    const { sendOpenGameLobbyMetrika } = useCasinoAnalyticsStore();
    const { sendOpenGameMetrika } = useCasinoGameAnalytics();
    const { setDemoStartMode, setStartGameAfterLogin, setIsGamePreview, startGame, setLoyaltySidebarOpened, toggleSidebarOpened, resetActiveGame, clearStartGameData, reloadActiveGame } = gamesStore;
    const { openGameInNewWindow } = useOpenGameInNewWindow();
    const metaParameters = computed(()=>({
            name: activeGame.value?.name,
            group: activeGame.value?.group.name,
            description: activeGame.value?.description,
            gameType: activeGame.value?.typeId,
            metaDescription: activeGame.value?.metaDescription
        }));
    const isStartGameLoading = ref(false);
    const isIframeLoaded = ref(false);
    const isReminderModalVisible = ref(showBonusReminder.value);
    const startGameError = ref();
    const reloadCounter = ref(0);
    const iframeLoadTimeout = computed(()=>scgExternalGames.value?.gameLoadTimeout ?? 0);
    function goToLoginPage() {
        router.push({
            name: RouteName.LOGIN
        });
    }
    function onToggleFavorite() {
        if (activeGame.value) toggleFavoriteGame(activeGame.value);
        if (!isLoggedIn.value) goToLoginPage();
    }
    function onClose(mode) {
        setFullscreenMode(false);
        router.forceBack(backToLobbyLocation.value);
        if (mode === CasinoGameCloseMethod.SWIPE) analytics.push(AnalyticsEvent.Z_CLOSE_PREVIEW_GAME, {
            closePreviewGame: CasinoGameCloseMethod.SWIPE
        });
    }
    function openGameFromLobbyMetrics(isDemo) {
        if (!isDemo && isCasinoLobbyRouteName(previousRouteName.value)) sendOpenGameLobbyMetrika();
    }
    function onGoToLogin() {
        if (isAppInFullscreenMode.value) onToggleFullscreen();
        setStartGameAfterLogin(true);
        goToLoginPage();
    }
    function sendAnalyticStartGameEvent() {
        if (activeGame.value) analytics.pushEgsGameEvent(mapActiveGameToAnalytics(activeGame.value));
    }
    function checkIsCurrentGame(id) {
        return isCasinoGameRouteName(route.name) && id === activeGame.value?.id;
    }
    async function handleStartError(id, isDemo, retryCounter, error) {
        if (!checkIsCurrentGame(id)) return;
        isFetchStartError(error);
        const { code } = error;
        if (isGeneralApiError(error)) throw error;
        startGameError.value = {
            message: error.message,
            code: code.toString()
        };
    }
    async function startIframeGame(id, isDemo) {
        let retryCounter = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
        if (!activeGame.value || isGamePreviewMode.value || activeGame.value.blockType) return;
        if (!isLoggedIn.value && !isDemo) return;
        isIframeLoaded.value = false;
        try {
            await startGame({
                id,
                isDemo,
                bankingUrl: getBankingUrl(),
                exitUrl: getExitUrl()
            }, false);
        } catch (error) {
            await handleStartError(id, isDemo, retryCounter, error);
            return;
        }
        if (checkIsCurrentGame(id) && !gameUrl.value) startGameError.value = {
            code: 'GAME_NOT_FOUND',
            message: $translate('WEB2_CASINO_GAME_NOT_FOUND').value
        };
    }
    async function play() {
        let isDemo = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        startGameError.value = void 0;
        if (!activeGame.value || activeGame.value.isUnavailable) return;
        if (!isLoggedIn.value && !isDemo) {
            if (activeGame.value.hasDemo) onGoToLogin();
            return;
        }
        if (activeGame.value.openMethod === GameOpenMethod.IFRAME) {
            setDemoStartMode(isDemo);
            setIsGamePreview(false);
        }
        // Player closed window while waiting for VPN connection
        if (!activeGame.value) return;
        if (activeGame.value.openMethod === GameOpenMethod.WINDOW) openGameInNewWindow(activeGame.value.id, isDemo);
        else startIframeGame(activeGame.value.id, isDemo);
        sendAnalyticStartGameEvent();
    }
    function onPlay() {
        let isDemo = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        openGameFromLobbyMetrics(isDemo);
        sendOpenGameMetrika(isDemo);
        setDemoStartMode(isDemo);
        play(isDemo);
    }
    function restartGame() {
        "1";
        play(isDemoState.value);
    }
    function onIframeLoaded() {
        isIframeLoaded.value = true;
    }
    function onClickSwitch() {
        let isRealGame = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (isRealGame && !isLoggedIn.value) {
            onGoToLogin();
            return;
        }
        if (isLoading.value) return;
        play(!isRealGame);
    }
    function onClickChat() {
        if (isLoggedIn.value) bus.emit(BusEvent.OPEN_CHAT, {});
        else onGoToLogin();
    }
    function onToggleFullscreen() {
        setFullscreenMode(!isAppInFullscreenMode.value);
        setLoyaltySidebarOpened(false);
    }
    function onRealPlay() {
        play(false);
    }
    function onErrorClick(action) {
        switch(action){
            case 'login':
                goToLoginPage();
                break;
            case 'refresh':
                reloadCounter.value += 1;
                restartGame();
                break;
            case 'providers':
                router.push({
                    name: isLiveEgsRoute(route) ? CasinoRouteName.CASINO_LIVE_GROUPS : CasinoRouteName.CASINO_GROUPS
                });
                break;
            case 'close':
                onClose();
                break;
            default:
                break;
        }
    }
    onBeforeUnmount(()=>{
        setFullscreenMode(false);
        if (!isCasinoGameRouteName(route.name)) resetActiveGame();
        clearStartGameData();
    });
    useCasinoGameStartPostMessage(onClose, restartGame);
    function setDefaultGameMode() {
        const isGameStartMode = true;
        setIsGamePreview(!isGameStartMode);
    }
    async function onRouteChanged() {
        if (!isCasinoGameRouteName(route.name)) return;
        if (!isDemoState.value && !isLoggedIn.value && activeGame.value?.hasDemo) {
            setDemoStartMode(true);
            onGoToLogin();
        }
        isIframeLoaded.value = false;
        isStartGameLoading.value = false;
        startGameError.value = void 0;
        reloadCounter.value = 0;
        clearStartGameData();
        setDefaultGameMode();
        if (startGameAfterLogin.value || !isGamePreviewMode.value) play(isDemoState.value);
    }
    watch(()=>route.fullPath, onRouteChanged, {
        immediate: true
    });
    async function onLogin() {
        await reloadActiveGame();
        if (startGameAfterLogin.value && isLoggedIn.value) {
            setDemoStartMode(false);
            setStartGameAfterLogin(false);
        }
        if (!isDemoState.value) await play(false);
    }
    function onLogout() {
        if (!isDemoState.value) onClose();
    }
    function onIframeError(error) {
        if (!isIframeLoaded.value) {
            startGameError.value = {
                code: 'GAME_UNAVAILABLE',
                message: $translate('WEB2_GAMES_GENERAL_ERROR_MESSAGE').value
            };
            // Send to opensearch logs
            let msg = 'Error in response from EGS game provider resource';
            if (error instanceof IFrameTimeoutError) msg = 'Timeout while waiting for a response from the EGS game provider resource';
            else if (error instanceof Error) msg = `Error in response from EGS game provider resource ${error.message}`;
            logger.warn(msg);
        }
    }
    watch(isLoggedIn, (newValue)=>{
        if (newValue) onLogin();
        else onLogout();
    });
    onMounted(()=>{
        if (freespinInfo.value) isReminderModalVisible.value = false;
    });
    watch(showBonusReminder, (newValue)=>{
        isReminderModalVisible.value = newValue;
    });
    function handleFreespinDialog() {
        isReminderModalVisible.value = false;
    }
    const { errorData } = useCasinoGameError(activeGame, isDemoUnavailable, startGameError, reloadCounter);
    return {
        errorData,
        isGamePreviewMode,
        metaParameters,
        activeGame,
        isStartGameLoading,
        isLoading,
        isIframeLoaded,
        isDemo: isDemoState,
        gameUrl,
        gameStartCode,
        showBonusReminder,
        closeUrl,
        isLoyaltySwitcherEnabled,
        isLoyaltySidebarOpened,
        hideLimitModalOnGamePage,
        maxBetLimit,
        iframeLoadTimeout,
        onIframeLoaded,
        onToggleFavorite,
        onClose,
        onPlay,
        restartGame,
        onClickSwitch,
        onClickChat,
        onToggleFullscreen,
        onGoToLogin,
        onRealPlay,
        toggleSidebarOpened,
        onErrorClick,
        handleFreespinDialog,
        onIframeError,
        isReminderModalVisible,
        freespinInfo
    };
}
