import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { VIframe } from 'web/src/components/Iframe';
import { useCasinoBetgamesIframeWidget } from 'web/src/modules/casino/submodules/betgames/components/CasinoBetgamesIframeWidget/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoBetgamesIframeWidget',
    props: {
        gameHtml: {},
        iframeUrl: {}
    },
    emits: [
        "scroll-top",
        "load"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { iframeName, iframeHeight, gameUrl, iframeMounted, emitLoad } = useCasinoBetgamesIframeWidget(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VIframe), {
                name: _unref(iframeName),
                src: _unref(gameUrl),
                allow: "autoplay; fullscreen; encrypted-media",
                "full-width": "",
                "full-height": "",
                "hide-title": "",
                style: _normalizeStyle({
                    height: `${_unref(iframeHeight)}px`
                }),
                onVnodeMounted: _unref(iframeMounted),
                onLoad: _unref(emitLoad)
            }, null, 8, [
                "name",
                "src",
                "style",
                "onVnodeMounted",
                "onLoad"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoBetgamesIframeWidget'
                ]
            ]);
        };
    }
});
