import { computed, onMounted, onUnmounted, ref, toRef } from 'vue';
import { AlertIconName } from '@leon-hub/icons';
import { FreeSpinType } from '@leon-hub/websocket/src/sdk-ws';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useWebSockets } from 'web/src/modules/core/composables';
import useWebSocketsConfig from 'web/src/modules/core/composables/site-config/useWebSocketsConfig';
import { useAppFullscreenStore, useDesktopModalStore } from 'web/src/modules/core/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
export default function useCasinoGameFreespinModal(props, emits) {
    const { $translate } = useI18n();
    const { locale } = useI18nLocale();
    const formatMoney = useFormatMoney();
    const { currency } = useCurrency();
    const { showDialog } = useDialogs();
    const { isFlexibleFreeSpinBetLimitNotificationEnabled } = useWebSocketsConfig();
    const { subscribeAuthorized } = useWebSockets();
    const isAppInFullscreenMode = toRef(useAppFullscreenStore(), 'isAppInFullscreenMode');
    const isProfileLayout = toRef(useDesktopModalStore(), 'isProfileLayout');
    const socketPollingTimeout = ref(5000);
    let unsubscriptions = [];
    const showCasinoGameFirstModal = ref(false);
    let hasVisibleDialog = false;
    const getFreespinTitle = (value)=>LanguageMicroSettings.plural([
            $translate('WEB2_FORTUNE_WHEEL_REWARD_TEXT_FREESPIN_ONE').value,
            $translate('WEB2_FORTUNE_WHEEL_REWARD_TEXT_FREESPIN_TWO').value,
            $translate('WEB2_FORTUNE_WHEEL_REWARD_TEXT_FREESPIN_MANY').value
        ], value, locale.value);
    const getWalletName = (walletId)=>1 === walletId ? $translate('WEB2_FREESPIN_WALLET_BONUS').value : $translate('WEB2_FREESPIN_WALLET_MAIN').value;
    const params = computed(()=>({
            freespin: `${props.freespinInfo?.count} ${getFreespinTitle(props.freespinInfo?.count || 0)}`,
            freespinLeft: `${props.freespinInfo?.left} ${getFreespinTitle(props.freespinInfo?.left || 0)}`,
            amount: formatMoney(props.freespinInfo?.amount || 0, {
                currency: props.freespinInfo?.currency || currency.value
            }),
            wallet: getWalletName(1)
        }));
    const casinoGameFirstModalProps = computed(()=>({
            isAlert: true,
            modal: {
                isOverlayCloseAllowed: false,
                width: ModalWidth.SMALL,
                title: $translate('WEB2_GAME_FREESPIN_FIRST_MODAL_TITLE').value,
                confirmMessage: $translate('WEB2_GAME_FREESPIN_FIRST_MODAL_DESCRIPTION', params).value,
                buttons: [
                    {
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_GAME_FREESPIN_FIRST_MODAL_BUTTON').value
                    }
                ]
            }
        }));
    const handleRestart = ()=>{
        hasVisibleDialog = false;
        emits('restart-game');
    };
    const handleToggleFullscreen = ()=>{
        if (isAppInFullscreenMode.value) emits('toggle-fullscreen');
    };
    const showCasinoGameContinueModal = ()=>{
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: $translate('WEB2_GAME_FREESPIN_CONTINUE_MODAL_TITLE').value,
                confirmMessage: $translate('WEB2_GAME_FREESPIN_CONTINUE_MODAL_DESCRIPTION', params).value,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.Present2
                }).image,
                buttons: [
                    {
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_GAME_FREESPIN_CONTINUE_MODAL_BUTTON').value
                    }
                ],
                dataTestId: 'freespin-continue'
            }
        });
    };
    const showCasinoGameInterruptedModal = (message)=>{
        if (!hasVisibleDialog && props.gameId === message.onFfsBetLimit.internalGameId) {
            hasVisibleDialog = true;
            handleToggleFullscreen();
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('WEB2_GAME_FREESPIN_INTERRUPTED_MODAL_TITLE').value,
                    confirmMessage: $translate('WEB2_GAME_FREESPIN_INTERRUPTED_MODAL_DESCRIPTION', params).value,
                    image: getImageOrIcon({
                        alertIcon: AlertIconName.Sandglass
                    }).image,
                    buttons: [
                        {
                            kind: ButtonKind.PRIMARY,
                            action: DialogAction.CONFIRM,
                            label: $translate('WEB2_GAME_FREESPIN_INTERRUPTED_MODAL_BUTTON').value
                        }
                    ],
                    dataTestId: 'freespin-interrupted'
                }
            }).subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    handleRestart();
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    handleRestart();
                }
            });
        }
    };
    const showCasinoGameCompletedModal = (freespinTargetWalletTypeId, internalGameId)=>{
        if (!hasVisibleDialog && !isProfileLayout.value && (props.gameId === internalGameId || !internalGameId)) {
            hasVisibleDialog = true;
            params.value.wallet = getWalletName(freespinTargetWalletTypeId);
            handleToggleFullscreen();
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('WEB2_GAME_FREESPIN_COMPLETED_MODAL_TITLE').value,
                    confirmMessage: $translate('WEB2_GAME_FREESPIN_COMPLETED_MODAL_DESCRIPTION', params).value,
                    image: getImageOrIcon({
                        alertIcon: AlertIconName.Fail
                    }).image,
                    buttons: [
                        {
                            kind: ButtonKind.PRIMARY,
                            action: DialogAction.CONFIRM,
                            label: $translate('WEB2_GAME_FREESPIN_COMPLETED_MODAL_BUTTON').value
                        }
                    ],
                    dataTestId: 'freespin-completed'
                }
            }).subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    handleRestart();
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    handleRestart();
                }
            });
        }
    };
    onMounted(()=>{
        const { freespinInfo } = props;
        if (freespinInfo && 0 !== freespinInfo.left) {
            if (freespinInfo.count === freespinInfo.left) showCasinoGameFirstModal.value = true;
            else showCasinoGameContinueModal();
            const onFfsBetLimit = subscribeAuthorized({
                method: 'onFfsBetLimit',
                onMessage: (message)=>showCasinoGameInterruptedModal(message),
                isEnabled: isFlexibleFreeSpinBetLimitNotificationEnabled,
                polling: {
                    timeout: socketPollingTimeout,
                    callback: async ()=>{},
                    callOnLogin: true
                }
            });
            const onFfsBetCountLimit = subscribeAuthorized({
                method: 'onFfsBetCountLimit',
                onMessage: (message)=>showCasinoGameCompletedModal(message.onFfsBetCountLimit.freespinTargetWalletTypeId, message.onFfsBetCountLimit.internalGameId),
                isEnabled: isFlexibleFreeSpinBetLimitNotificationEnabled,
                polling: {
                    timeout: socketPollingTimeout,
                    callback: async ()=>{},
                    callOnLogin: true
                }
            });
            const onFreeSpinResult = subscribeAuthorized({
                method: 'onFreeSpinResult',
                onMessage: (message)=>message.onFreeSpinResult.freespinType === FreeSpinType.FLEXIBLE ? showCasinoGameCompletedModal(message.onFreeSpinResult.freespinTargetWalletType) : {},
                isEnabled: isFlexibleFreeSpinBetLimitNotificationEnabled,
                polling: {
                    timeout: socketPollingTimeout,
                    callback: async ()=>{},
                    callOnLogin: true
                }
            });
            unsubscriptions = [
                onFfsBetLimit.unsubscribe,
                onFfsBetCountLimit.unsubscribe,
                onFreeSpinResult.unsubscribe
            ];
        }
    });
    onUnmounted(()=>{
        if (unsubscriptions.length) for (const unsubscribe of unsubscriptions)unsubscribe();
    });
    const onClose = ()=>{
        showCasinoGameFirstModal.value = false;
    };
    return {
        showCasinoGameFirstModal,
        casinoGameFirstModalProps,
        onClose
    };
}
