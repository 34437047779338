import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import PromotionCashbackWidget from 'web/src/modules/promotions/components/PromotionCashbackWidget/PromotionsCashbackWidget.vue';
import { useCasinoGameCashbackContainer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameCashbackContainer',
    setup (__props) {
        const { isVisible, cashbackAmount, timestamp, onBalanceClick, hideWidget } = useCasinoGameCashbackContainer();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isVisible) ? _withDirectives((_openBlock(), _createBlock(PromotionCashbackWidget, {
                key: 0,
                "cashback-amount": _unref(cashbackAmount),
                timestamp: _unref(timestamp),
                onClick: _unref(onBalanceClick),
                onSend: _unref(hideWidget)
            }, null, 8, [
                "cashback-amount",
                "timestamp",
                "onClick",
                "onSend"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoGameCashbackContainer'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
