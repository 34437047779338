import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
let CasinoGroupsPagePrefetch = class CasinoGroupsPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const { loadGroups } = useEgsGroupsStore();
        loadGroups().then();
        next();
        return Promise.resolve();
    }
};
export { CasinoGroupsPagePrefetch as default };
