import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { VJumbotron } from 'web/src/components/Jumbotron';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLobbyBlockError',
    props: {
        groupName: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-lobby-block-error'])
            }, [
                _createVNode(_unref(VJumbotron), {
                    heading: _ctx.$t('WEB2_LOBBY_PROVIDER_BLOCKED_TTL'),
                    icon: _unref(IconName).LOCATION_RESTRICTED,
                    "alert-icon": void 0,
                    "icon-size": _unref(IconSize).SIZE_80,
                    "alert-icon-size": void 0
                }, {
                    default: _withCtx(()=>[
                            _createVNode(_unref(TextSlotPlaceholder), {
                                text: _ctx.$t('WEB2_LOBBY_PROVIDER_BLOCKED_MSG')
                            }, {
                                provider: _withCtx(()=>[
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['casino-lobby-block-error__group-name'])
                                        }, _toDisplayString(_ctx.groupName), 3)
                                    ]),
                                _: 1
                            }, 8, [
                                "text"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "heading",
                    "icon",
                    "alert-icon",
                    "icon-size",
                    "alert-icon-size"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLobbyBlockError'
                ]
            ]);
        };
    }
});
