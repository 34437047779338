import { watch, toRef, computed } from 'vue';
import { Timer } from '@leon-hub/utils';
import { WagerResultType } from '@leon-hub/websocket/src/sdk-ws';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useAuthorizedLifecycleWebSockets } from 'web/src/modules/core/composables/web-sockets/useLifecycleWebSockets';
export default function useCasinoGameBonus(restartGame) {
    const bonusStore = useBonusStore();
    const bonus = toRef(bonusStore, 'bonusCbc');
    const wagerCreatedData = toRef(bonusStore, 'wagerCreatedData');
    const { isCbcBonusWithdrawalAmountEnabled } = useWebSocketsConfig();
    const scgStore = useSiteConfigStore();
    const formatMoney = useFormatMoney();
    const { currency } = useCurrency();
    const startGameData = toRef(useCasinoGameStore(), 'startGameData');
    const { isFlexibleFreeSpinBetLimitNotificationEnabled } = useWebSocketsConfig();
    let restartGameTimeout = 0;
    const cbcBonus = computed(()=>formatMoney(bonus.value?.walletBalanceNumber || 0, {
            currency: bonus.value?.currency || currency.value
        }));
    function clearRestartGameTimeout() {
        if (restartGameTimeout) {
            Timer.clearTimeout(restartGameTimeout);
            restartGameTimeout = 0;
        }
    }
    watch(bonus, (newValue, oldValue)=>{
        if (!newValue) return;
        if (newValue.walletBalanceNumber > 0) clearRestartGameTimeout();
        if (!newValue.walletBalanceNumber && Number(oldValue?.walletBalanceNumber) > 0) {
            clearRestartGameTimeout();
            restartGameTimeout = Timer.setTimeout(restartGame, scgStore.externalGames?.gameZeroBalanceReloadTimeout || 10000);
        }
    });
    watch(wagerCreatedData, ()=>{
        const isFlexibleFreespinEnabled = isFlexibleFreeSpinBetLimitNotificationEnabled.value && startGameData.value?.freeSpinInfo;
        if (!isFlexibleFreespinEnabled) restartGame();
    }, {
        deep: true
    });
    useAuthorizedLifecycleWebSockets({
        method: 'onWagerResult',
        onMessage: (data)=>{
            if (data.onWagerResult.resultType === WagerResultType.EMPTY_BALANCE) restartGame();
        },
        isEnabled: isCbcBonusWithdrawalAmountEnabled
    });
    return {
        cbcBonus
    };
}
