import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
const useDevtoolsDetectorStore = defineStore('devtools-detector', ()=>{
    const isOpened = ref(false);
    const listenersCounter = ref(0);
    const siteConfigStore = useSiteConfigStore();
    const settingsBlock = toRef(siteConfigStore, 'settingsBlock');
    const isDevtoolsTrackerDisabled = computed(()=>!!settingsBlock.value?.isDevtoolsTrackerDisabled);
    let isLaunched = false;
    let localDetector;
    function stopDetector() {
        if (localDetector && isLaunched) {
            localDetector.removeListener(callback);
            localDetector.stop();
            isLaunched = false;
            isOpened.value = false;
        }
    }
    function stop() {
        if (!isOpened.value && listenersCounter.value <= 0) stopDetector();
    }
    function callback(isDevtoolsOpened) {
        isOpened.value = isDevtoolsOpened;
        stop();
    }
    async function loadDetector() {
        if (!localDetector) localDetector = await import('devtools-detector');
    }
    async function launch() {
        if (!isLaunched && !isDevtoolsTrackerDisabled.value) {
            isLaunched = true;
            await loadDetector();
            if (localDetector) {
                localDetector.launch();
                localDetector.addListener(callback);
            }
        }
    }
    function addListener() {
        listenersCounter.value += 1;
        launch();
    }
    function removeListener() {
        listenersCounter.value -= 1;
        stop();
    }
    watch(isDevtoolsTrackerDisabled, (newValue)=>{
        if (newValue) stopDetector();
    }, {
        immediate: true
    });
    return {
        isOpened,
        addListener,
        removeListener
    };
});
export default useDevtoolsDetectorStore;
