import { computed, ref } from 'vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { CurrentModal } from '../enums';
import { getModalBonusProperties, getModalConfirmProperties, getModalErrorHandlerProperties } from './utils';
export default function useWithdrawalContainer(props, emits) {
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const { convertToBaseError } = useErrorsConverter();
    const modalSelector = ModalSelector.BODY;
    const currentModal = ref(CurrentModal.NONE);
    let withdrawalFunction;
    const modalConfirmProperties = computed(()=>getModalConfirmProperties(props, $translate));
    const modalBonusProperties = computed(()=>getModalBonusProperties($translate));
    const onCloseModal = ()=>{
        currentModal.value = CurrentModal.NONE;
        emits('modal-close');
    };
    const withdrawBonus = (func)=>{
        withdrawalFunction = func;
        currentModal.value = CurrentModal.CONFIRM;
    };
    const withdrawErrorHandler = (error)=>{
        currentModal.value = CurrentModal.NONE;
        showDialog(getModalErrorHandlerProperties(error.message, $translate)).promise.then(onCloseModal);
    };
    const withdrawBonusHandler = async ()=>{
        try {
            if (withdrawalFunction) await Promise.resolve(withdrawalFunction());
            currentModal.value = CurrentModal.BONUS;
        } catch (rawError) {
            withdrawErrorHandler(convertToBaseError(rawError));
        }
    };
    const onClickModal = async (param)=>{
        let { action } = param;
        if (currentModal.value === CurrentModal.CONFIRM) switch(action){
            case DialogAction.MODAL_CLOSE:
                currentModal.value = CurrentModal.NONE;
                break;
            case DialogAction.CONFIRM:
                await withdrawBonusHandler();
                currentModal.value = CurrentModal.BONUS;
                break;
            default:
                break;
        }
        if (action === DialogAction.MODAL_CLOSE && currentModal.value === CurrentModal.BONUS) onCloseModal();
    };
    return {
        modalSelector,
        currentModal,
        modalConfirmProperties,
        modalBonusProperties,
        onClickModal,
        onCloseModal,
        withdrawBonus
    };
}
