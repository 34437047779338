import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import CasinoGroupsList from 'web/src/modules/casino/components/CasinoGroupsList/CasinoGroupsList.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useCasinoGroupsRoutePage } from 'web/src/modules/casino/submodules/groups/pages/CasinoGroupsRoutePage/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGroupsRoutePage',
    setup (__props) {
        const { list, selectedId, searchText, isSearchInProgress, isNoSearchResults, onSearchInput, onProviderSelect, onSelectedItemLoaded, onSearchClear } = useCasinoGroupsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-slots-provider-page'])
            }, [
                _createVNode(VSearchInput, {
                    value: _unref(searchText),
                    "is-autofocus": true,
                    placeholder: _ctx.$t('WEB2_EGS_GROUPS_SEARCH_PLACEHOLDER'),
                    onInput: _unref(onSearchInput),
                    onClear: _unref(onSearchClear)
                }, null, 8, [
                    "value",
                    "is-autofocus",
                    "placeholder",
                    "onInput",
                    "onClear"
                ]),
                _createVNode(CasinoGroupsList, {
                    class: _normalizeClass(_ctx.$style['casino-slots-provider-page__list']),
                    groups: _unref(list),
                    "selected-id": _unref(selectedId),
                    onClickGroup: _unref(onProviderSelect),
                    onSelectedLoaded: _unref(onSelectedItemLoaded)
                }, null, 8, [
                    "class",
                    "groups",
                    "selected-id",
                    "onClickGroup",
                    "onSelectedLoaded"
                ]),
                _unref(isSearchInProgress) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : _createCommentVNode("", true),
                _unref(isNoSearchResults) ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['casino-slots-provider-page__no-results'])
                }, _toDisplayString(_ctx.$t('JAVA_SEARCH_NORESULTS')), 3)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGroupsRoutePage'
                ]
            ]);
        };
    }
});
