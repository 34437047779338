import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import CasinoLoyaltyProgramIcon from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameSideBarSwitcher',
    props: {
        isSideBarOpened: {
            type: Boolean
        },
        imageSrc: {
            default: require('web/src/assets/images/beginner.png')
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const iconName = computed(()=>props.isSideBarOpened ? IconName.EXPAND_RIGHT : IconName.EXPAND_LEFT);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-game-sidebar-switcher']]: true,
                    [_ctx.$style['casino-game-sidebar-switcher--opened']]: _ctx.isSideBarOpened
                }),
                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
            }, [
                _createVNode(_unref(VIcon), {
                    name: iconName.value,
                    size: _unref(IconSize).SIZE_16,
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-switcher__icon'])
                }, null, 8, [
                    "name",
                    "size",
                    "class"
                ]),
                _createVNode(CasinoLoyaltyProgramIcon, {
                    size: _unref(CasinoLoyaltyProgramIconSizes).SIZE_32,
                    "image-src": _ctx.imageSrc,
                    class: _normalizeClass(_ctx.$style['casino-game-sidebar-switcher__level-icon'])
                }, null, 8, [
                    "size",
                    "image-src",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameSideBarSwitcher'
                ]
            ]);
        };
    }
});
