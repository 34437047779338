import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config-names';
import { logger } from '@leon-hub/logging';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';
// eslint-disable-next-line max-len
export default function useCasinoGameSideBarContainer(props) {
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const { $translate } = useI18n();
    const { push: pushAnalytics } = useAnalytics();
    const bonusStore = useBonusStore();
    const loyaltyShopStore = useLoyaltyShopStore();
    const casinoLoyaltyStore = useCasinoLoyaltyStore();
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const isBonusLoaded = toRef(bonusLoyaltyStore, 'bonusLoaded');
    const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');
    const remainingAmountNumber = toRef(bonusLoyaltyStore, 'remainingAmountNumber');
    const progress = toRef(bonusLoyaltyStore, 'progress');
    const progressPercentString = toRef(bonusLoyaltyStore, 'progressPercentString');
    const isParticipating = toRef(bonusLoyaltyStore, 'isParticipating');
    const bonus = toRef(bonusStore, 'bonusCbc');
    const currentLevelCardProperties = toRef(casinoLoyaltyStore, 'currentLevelCardProperties');
    const levelSettingsWithStatus = toRef(casinoLoyaltyStore, 'levelSettingsWithStatus');
    const currentPoints = toRef(casinoLoyaltyStore, 'currentPoints');
    const currentLevelId = toRef(casinoLoyaltyStore, 'currentLevelId');
    const isLeonShopEnabled = toRef(useSiteConfigStore(), 'isLeonShopEnabled');
    const offers = toRef(loyaltyShopStore, 'offers');
    const gamesStore = useCasinoGameStore();
    const isLoyaltySidebarOpened = toRef(gamesStore, 'isLoyaltySidebarOpened');
    const { toggleSidebarOpened } = gamesStore;
    const activeTab = ref("program-loyalty");
    const isShopOffersLoaded = ref(false);
    const isLoyaltyProgramTab = computed(()=>"program-loyalty" === activeTab.value);
    const isMultiTab = computed(()=>isBonusLoaded.value && !props.isHideMyBonuses);
    const tabsProperties = computed(()=>{
        const items = [
            {
                id: "program-loyalty",
                label: $translate('WEB2_GAME_SIDEBAR_LEVEL_TITLE').value
            }
        ];
        if (isBonusLoaded.value && !props.isHideMyBonuses) items.push({
            id: "my-bonuses",
            label: $translate('WEB2_GAME_SIDEBAR_MY_POINTS').value
        });
        return {
            items,
            activeId: items.length > 1 ? activeTab.value : '',
            type: TabsType.NORMAL,
            fullWidth: true
        };
    });
    function showLevelDescription(id) {
        toggleSidebarOpened();
        router.push({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_ID,
            params: {
                id: String(id)
            }
        });
    }
    function showCurrentLevelDescription() {
        showLevelDescription(currentLevelId.value);
    }
    function onShopItemClick(id) {
        pushAnalytics(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                leonshop: 'item'
            }
        });
        router.push({
            name: RouteName.SHOP_ITEM_DETAILS,
            params: {
                itemId: `${id}`
            }
        });
    }
    function handleExchange() {
        pushAnalytics(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                leonshop: 'exchange'
            }
        });
        router.push({
            name: RouteName.BONUS_WITHDRAWALS
        });
    }
    async function handleParticipate() {
        try {
            await bonusLoyaltyStore.confirmBonus();
            bonusLoyaltyStore.setIsParticipating(true);
        } catch  {
            logger.warn('something went wrong with bonus confirmation');
        }
    }
    function onTabClick(tabName) {
        activeTab.value = tabName;
    }
    onMounted(async ()=>{
        const promises = [];
        if (isLoggedIn.value) promises.push(casinoLoyaltyStore.fetchCasinoLoyaltyStatus(), bonusLoyaltyStore.fetchBonusLoyalty());
        if (isLeonShopEnabled.value) {
            const loadCustomerOffers = async ()=>{
                await loyaltyShopStore.fetchCustomerOffers();
                isShopOffersLoaded.value = true;
            };
            promises.push(loadCustomerOffers());
        }
        await Promise.all(promises);
    });
    return {
        isLoggedIn,
        isLoyaltySidebarOpened,
        bonus,
        currentLevelCardProperties,
        isLoyaltyProgramTab,
        isBonusLoaded,
        levelSettingsWithStatus,
        amountNumber,
        remainingAmountNumber,
        progress,
        progressPercentString,
        isParticipating,
        isLeonShopEnabled,
        isShopOffersLoaded,
        offers,
        isMultiTab,
        currentPoints,
        tabsProperties,
        toggleSidebarOpened,
        handleParticipate,
        showLevelDescription,
        showCurrentLevelDescription,
        onShopItemClick,
        handleExchange,
        onTabClick
    };
}
