import { computed } from 'vue';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useCasinoGameBonus(props, emit) {
    const formatMoney = useFormatMoney();
    const bonusTitle = computed(()=>props.item?.campaignName || '');
    const balance = computed(()=>{
        if (!props.item) return '';
        const { walletBalanceNumber, currency } = props.item;
        return formatMoney(walletBalanceNumber, {
            currency
        });
    });
    const isBonusActive = computed(()=>!!props.item?.actionUrl);
    const progress = computed(()=>100 * (props.item?.progress || 0));
    const expiringThrough = computed(()=>props.item?.expiringThrough || '');
    const amount = computed(()=>{
        if (!props.item) return '';
        return formatMoney(props.item.amountNumber, {
            hideCurrency: true
        });
    });
    const completedAmount = computed(()=>{
        if (!props.item) return '';
        const { currency } = props.item;
        return formatMoney(props.item.amountNumber, {
            currency
        });
    });
    const requiredAmount = computed(()=>{
        if (!props.item) return '';
        const { requiredAmountNumber, currency } = props.item;
        return formatMoney(requiredAmountNumber, {
            currency
        });
    });
    const isProgressComplete = computed(()=>!!props.item && props.item.progress >= 1 && 0 !== props.item.walletBalanceNumber);
    function onClickBonusDetails() {
        emit('click-bonus-details', props.item);
    }
    function onClickWithdrawalBonus() {
        emit('withdraw-bonus');
    }
    return {
        bonusTitle,
        balance,
        isBonusActive,
        progress,
        expiringThrough,
        amount,
        completedAmount,
        requiredAmount,
        isProgressComplete,
        onClickBonusDetails,
        onClickWithdrawalBonus
    };
}
