import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoCategoryListItem',
    props: {
        location: {},
        label: {},
        isActive: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(VRouterLink, {
                to: _ctx.location,
                disabled: _ctx.isLoading,
                "save-scroll-position": "",
                class: _normalizeClass({
                    [_ctx.$style['casino-category-list-item']]: true,
                    [_ctx.$style['casino-category-list-item--loading']]: _ctx.isLoading,
                    [_ctx.$style['casino-category-list-item--active']]: !_ctx.isLoading && _ctx.isActive
                })
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['casino-category-list-item__inner'])
                        }, [
                            _renderSlot(_ctx.$slots, "icon"),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['casino-category-list-item__inner-label'])
                            }, _toDisplayString(_ctx.label), 3)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['casino-category-list-item__skeleton'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['casino-category-list-item__skeleton-icon'])
                            }, null, 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['casino-category-list-item__skeleton-label'])
                            }, null, 2)
                        ], 2)
                    ]),
                _: 3
            }, 8, [
                "to",
                "disabled",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoCategoryListItem'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'casino-category-list-item',
                        active: _ctx.isActive
                    }
                ]
            ]);
        };
    }
});
