import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { useCasinoGameFreespinModal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameFreespinModal',
    props: {
        freespinInfo: {},
        gameId: {}
    },
    emits: [
        "restart-game",
        "toggle-fullscreen"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { showCasinoGameFirstModal, casinoGameFirstModalProps, onClose } = useCasinoGameFreespinModal(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY
            }, {
                default: _withCtx(()=>[
                        _unref(showCasinoGameFirstModal) ? (_openBlock(), _createBlock(ConfirmModal, _mergeProps({
                            key: 0
                        }, _unref(casinoGameFirstModalProps), {
                            onClose: _unref(onClose),
                            onButtonClick: _unref(onClose)
                        }), {
                            icon: _withCtx(()=>[
                                    _createElementVNode("img", {
                                        src: require('web/src/assets/images/fortune-wheel/firework.svg'),
                                        class: _normalizeClass(_ctx.$style['casino-game-freespin__firework']),
                                        alt: ""
                                    }, null, 10, _hoisted_1)
                                ]),
                            _: 1
                        }, 16, [
                            "onClose",
                            "onButtonClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoGameFreespinModal'
                ]
            ]);
        };
    }
});
