import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import useBonusPointsContainer from 'web/src/modules/bonuses/components/BonusPointsContainer/composables/useBonusPointsContainer';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusPointsContainer',
    props: {
        isSmall: {
            type: Boolean
        },
        isShort: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { loyaltyProgramEgsStatus, bonusLoaded, openBonusLoyaltyPage, progressProperties, pointsLabelProperties, progress, requiredAmount, buttonProperties, handleExchange } = useBonusPointsContainer(props);
        const { isLoggedIn } = useIsLoggedIn();
        const iconProperties = {
            name: IconName.EXPAND_RIGHT,
            size: IconSize.SIZE_16
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isLoggedIn) && _unref(loyaltyProgramEgsStatus) && _unref(bonusLoaded) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['bonus-points']]: true,
                    [_ctx.$style['bonus-points--small']]: _ctx.isSmall,
                    [_ctx.$style['bonus-points--short']]: _ctx.isShort
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(openBonusLoyaltyPage) && _unref(openBonusLoyaltyPage)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bonus-points__left'])
                }, [
                    _createVNode(VCircularProgress, _mergeProps(_unref(progressProperties), {
                        class: {
                            [_ctx.$style['bonus-points__progress']]: true
                        }
                    }), {
                        default: _withCtx(()=>[
                                _createElementVNode("img", {
                                    class: _normalizeClass(_ctx.$style['bonus-points__progress-icon']),
                                    src: require('web/src/assets/images/gift-leon.svg'),
                                    alt: ""
                                }, null, 10, _hoisted_1)
                            ]),
                        _: 1
                    }, 16, [
                        "class"
                    ]),
                    _createElementVNode("div", null, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bonus-points__title'])
                        }, _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_BONUS_POINTS_TITLE')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bonus-points__quantity'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['bonus-points__quantity-label'])
                            }, _toDisplayString(_unref(pointsLabelProperties)), 3),
                            _createVNode(_unref(VIcon), {
                                class: _normalizeClass(_ctx.$style['bonus-points__quantity-icon']),
                                name: _unref(IconName).COIN_L
                            }, null, 8, [
                                "class",
                                "name"
                            ])
                        ], 2)
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bonus-points__right'])
                }, [
                    _unref(progress) < 1 ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bonus-points__required'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bonus-points__required-text'])
                        }, _toDisplayString(_unref(requiredAmount)) + " " + _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_BEFORE_EXCHANGE')), 3),
                        _createVNode(_unref(VIcon), _mergeProps({
                            class: _ctx.$style['bonus-points__required-icon']
                        }, iconProperties), null, 16, [
                            "class"
                        ])
                    ], 2)) : (_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 1
                    }, _unref(buttonProperties), {
                        class: _ctx.$style['bonus-points__button'],
                        onClick: _unref(handleExchange)
                    }), null, 16, [
                        "class",
                        "onClick"
                    ]))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusPointsContainer'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
