import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { defineAsyncComponent } from 'vue';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { useCustomerLogin } from 'web/src/modules/customer/composables';
import CasinoGameStart from 'web/src/modules/casino/submodules/game/views/CasinoGameStart/CasinoGameStart.vue';
import CasinoGameFreespinModal from 'web/src/modules/casino/submodules/game/views/CasinoGameFreespinModal/CasinoGameFreespinModal.vue';
import SCasinoGamePreview from 'web/src/modules/casino/submodules/game/views/CasinoGamePreview/components/SCasinoGamePreview/SCasinoGamePreview.vue';
import CasinoGamePreview from 'web/src/modules/casino/submodules/game/views/CasinoGamePreview/CasinoGamePreview.vue';
import CasinoGameErrorView from 'web/src/modules/casino/submodules/game/views/CasinoGameErrorView/CasinoGameErrorView.vue';
import { useCasinoGameRoutePage, useCasinoGameCashback, useCasinoGameRoutePageCordova, useCasinoGameBonus, useCasinoGameSyncState } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameRoutePage',
    setup (__props) {
        const CasinoGameBonusReminderModal = defineAsyncComponent(()=>import('web/src/modules/casino/submodules/game/views/CasinoGameBonusReminderModal/CasinoGameBonusReminderModal.vue'));
        const { activeGame, isGamePreviewMode, metaParameters, isStartGameLoading, isLoyaltySwitcherEnabled, isLoading, isDemo, gameUrl, gameStartCode, showBonusReminder, isIframeLoaded, closeUrl, isLoyaltySidebarOpened, hideLimitModalOnGamePage, maxBetLimit, errorData, onToggleFavorite, onClose, onPlay, restartGame, onIframeLoaded, onIframeError, onClickSwitch, onClickChat, onToggleFullscreen, onGoToLogin, onRealPlay, toggleSidebarOpened, onErrorClick, handleFreespinDialog, isReminderModalVisible, freespinInfo, iframeLoadTimeout } = useCasinoGameRoutePage();
        const { login } = useCustomerLogin();
        const { isCashbackDisplay } = useCasinoGameCashback();
        const { cbcBonus } = useCasinoGameBonus(restartGame);
        useSeoMetaParameters(metaParameters);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(activeGame) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(CasinoGameStart, {
                    key: 2,
                    "active-game": _unref(activeGame),
                    "is-loading": _unref(isLoading),
                    "is-frame-loaded": _unref(isIframeLoaded),
                    "start-code-type": _unref(gameStartCode),
                    "is-demo": _unref(isDemo),
                    "game-url": _unref(gameUrl),
                    error: _unref(errorData),
                    "user-login": _unref(login),
                    "is-chat-enabled": "",
                    "close-href": _unref(closeUrl),
                    "is-loyalty-switcher-enabled": _unref(isLoyaltySwitcherEnabled),
                    "is-side-bar-opened": _unref(isLoyaltySidebarOpened),
                    "is-cashback-display": _unref(isCashbackDisplay),
                    timeout: _unref(iframeLoadTimeout),
                    onSetFrameLoaded: _unref(onIframeLoaded),
                    onClickSwitch: _unref(onClickSwitch),
                    onFavorite: _unref(onToggleFavorite),
                    onClickChat: _unref(onClickChat),
                    onFullscreen: _unref(onToggleFullscreen),
                    onClose: _unref(onClose),
                    onErrorClick: _unref(onErrorClick),
                    onGoToLogin: _unref(onGoToLogin),
                    onGoToRealGame: _unref(onRealPlay),
                    onSideBarToggle: _unref(toggleSidebarOpened),
                    onRestartGame: _unref(restartGame),
                    onOnFrameError: _unref(onIframeError)
                }, null, 8, [
                    "active-game",
                    "is-loading",
                    "is-frame-loaded",
                    "start-code-type",
                    "is-demo",
                    "game-url",
                    "error",
                    "user-login",
                    "close-href",
                    "is-loyalty-switcher-enabled",
                    "is-side-bar-opened",
                    "is-cashback-display",
                    "timeout",
                    "onSetFrameLoaded",
                    "onClickSwitch",
                    "onFavorite",
                    "onClickChat",
                    "onFullscreen",
                    "onClose",
                    "onErrorClick",
                    "onGoToLogin",
                    "onGoToRealGame",
                    "onSideBarToggle",
                    "onRestartGame",
                    "onOnFrameError"
                ])),
                _unref(showBonusReminder) ? (_openBlock(), _createBlock(_unref(CasinoGameBonusReminderModal), {
                    key: 3,
                    "hide-limit-modal-on-game-page": _unref(hideLimitModalOnGamePage),
                    "max-bet-limit": _unref(maxBetLimit),
                    "bonus-balance": _unref(cbcBonus),
                    onClose: _unref(handleFreespinDialog)
                }, null, 8, [
                    "hide-limit-modal-on-game-page",
                    "max-bet-limit",
                    "bonus-balance",
                    "onClose"
                ])) : _createCommentVNode("", true),
                _unref(isLoading) || _unref(isReminderModalVisible) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(CasinoGameFreespinModal, {
                    key: 4,
                    "freespin-info": _unref(freespinInfo),
                    "game-id": _unref(activeGame).id,
                    onRestartGame: _unref(restartGame),
                    onToggleFullscreen: _unref(onToggleFullscreen)
                }, null, 8, [
                    "freespin-info",
                    "game-id",
                    "onRestartGame",
                    "onToggleFullscreen"
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'CasinoGameRoutePage'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
