import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useCasinoGameListContainer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameListContainer',
    setup (__props) {
        const { lobbyBlockProps } = useCasinoGameListContainer();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LobbyBlock), _normalizeProps(_guardReactiveProps(_unref(lobbyBlockProps))), null, 16)), [
                [
                    _directive_auto_id,
                    'CasinoGameListContainer'
                ]
            ]);
        };
    }
});
