import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, normalizeProps as _normalizeProps, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import CasinoCategoryList from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryList/CasinoCategoryList.vue';
import CasinoCategoryListItem from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryListItem/CasinoCategoryListItem.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { useCasinoCategoryTabs } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryTabs/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoCategoryTabs',
    props: {
        list: {},
        isLoading: {
            type: Boolean
        }
    },
    setup (__props) {
        const { getIconProperties } = useCasinoCategoryTabs();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(CasinoCategoryList, {
                list: _ctx.list,
                class: _normalizeClass(_ctx.$style['casino-category-tabs'])
            }, {
                item: _withCtx((param)=>{
                    let { item } = param;
                    return [
                        _createVNode(CasinoCategoryListItem, {
                            label: item.name,
                            "is-active": item.isActive,
                            "is-loading": _ctx.isLoading,
                            location: item.location,
                            class: _normalizeClass(_ctx.$style['casino-category-tabs__item'])
                        }, {
                            icon: _withCtx(()=>[
                                    item.iconUrl ? (_openBlock(), _createBlock(VImage, {
                                        key: 0,
                                        src: item.iconUrl,
                                        class: _normalizeClass({
                                            [_ctx.$style['casino-category-tabs__image']]: true,
                                            [_ctx.$style['casino-category-tabs__image--active']]: item.isActive
                                        })
                                    }, null, 8, [
                                        "src",
                                        "class"
                                    ])) : (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                                        key: 1
                                    }, _unref(getIconProperties)(item))), null, 16))
                                ]),
                            _: 2
                        }, 1032, [
                            "label",
                            "is-active",
                            "is-loading",
                            "location",
                            "class"
                        ])
                    ];
                }),
                _: 1
            }, 8, [
                "list",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoCategoryTabs'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'casino-category-list',
                        loading: _ctx.isLoading
                    }
                ]
            ]);
        };
    }
});
