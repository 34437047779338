import windowOpen from 'web/src/utils/windowOpen';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import { useCasinoActiveGame } from 'web/src/modules/casino/submodules/game/composables';
import useCasinoGameExitUrls from './useCasinoGameExitUrls';
export default function useOpenGameInNewWindowWeb() {
    const { getNewWindowUrl } = useCasinoGameExitUrls();
    const { setVisitedGame } = useLobbyStore();
    const { activeGame } = useCasinoActiveGame();
    let gameWindow = null;
    function closeGameWindow() {
        if (gameWindow) {
            gameWindow.close();
            gameWindow = null;
        }
    }
    function onFocusBack() {
        setVisitedGame(activeGame.value);
        window.removeEventListener('focus', onFocusBack);
    }
    function openGameInNewWindow(gameId) {
        let isDemo = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        closeGameWindow();
        gameWindow = windowOpen(getNewWindowUrl(gameId, isDemo));
        window.addEventListener('focus', onFocusBack);
    }
    return {
        openGameInNewWindow
    };
}
