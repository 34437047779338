import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, unref as _unref, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { useCasinoCategoryList } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryList/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoCategoryList',
    props: {
        list: {
            default: ()=>[]
        }
    },
    setup (__props) {
        const props = __props;
        const route = useRoute();
        const { swiper } = useCasinoCategoryList(props);
        watch(()=>route.name, ()=>{
            swiper.value?.resetScrollPosition();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-category-list'])
            }, [
                _createVNode(_unref(VSwiper), {
                    ref_key: "swiper",
                    ref: swiper,
                    "is-scroll-snap-enabled": ""
                }, _createSlots({
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: item.id,
                                    class: _normalizeClass(_ctx.$style['casino-category-list__slide'])
                                }, {
                                    default: _withCtx(()=>[
                                            _renderSlot(_ctx.$slots, "item", {
                                                item: item
                                            })
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class"
                                ]))), 128))
                        ]),
                    _: 2
                }, [
                    {
                        name: "pagination-footer",
                        fn: _withCtx(()=>[
                                _createVNode(SwiperNavigationButtons)
                            ]),
                        key: "0"
                    }
                ]), 1536)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoCategoryList'
                ]
            ]);
        };
    }
});
