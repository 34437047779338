import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, ref } from 'vue';
import EgsGameIframe from 'web/src/modules/egs/components/EgsGameIframe/EgsGameIframe.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import CasinoGameLoader from 'web/src/modules/casino/components/CasinoGameLoader/CasinoGameLoader.vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { PanelLayout } from 'web/src/modules/casino/submodules/game/enums';
import SCasinoGameSideBarContainer from 'web/src/modules/casino/submodules/game/views/CasinoGameSideBarContainer/components/SCasinoGameSideBarContainer.vue';
import CasinoGameVpnLoader from 'web/src/modules/casino/submodules/game/views/CasinoGameVpnLoader/CasinoGameVpnLoader.vue';
import CasinoControlBar from 'web/src/modules/casino/submodules/game/components/CasinoGameControlBar/CasinoGameControlBar.vue';
import CasinoGameIframeError from 'web/src/modules/casino/components/CasinoGameIframeError/CasinoGameIframeError.vue';
import CasinoGameCashbackContainer from 'web/src/modules/casino/submodules/game/views/CasinoGameCashbackContainer/CasinoGameCashbackContainer.vue';
import CasinoGameLeaderBoard from 'web/src/modules/casino/submodules/game/views/CasinoGameLeaderBoard/CasinoGameLeaderBoard.vue';
import CasinoGameDescription from 'web/src/modules/casino/submodules/game/components/CasinoGameDescription/CasinoGameDescription.vue';
import CasinoGameDemoBar from 'web/src/modules/casino/submodules/game/components/CasinoGameDemoBar/CasinoGameDemoBar.vue';
import { useDevToolsDetector } from 'web/src/modules/core/composables/browser';
import CasinoGameSideBarContainer from '../CasinoGameSideBarContainer/CasinoGameSideBarContainer.vue';
import CasinoGameBonusContainer from '../CasinoGameBonusContainer/CasinoGameBonusContainer.vue';
import CasinoGameListContainer from '../CasinoGameListContainer/CasinoGameListContainer.vue';
import { useCasinoGameStart } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameStart',
    props: {
        activeGame: {},
        isFrameLoaded: {
            type: Boolean
        },
        startCodeType: {},
        isDemo: {
            type: Boolean
        },
        gameUrl: {
            default: ''
        },
        isLoading: {
            type: Boolean
        },
        error: {},
        userLogin: {
            default: ''
        },
        isChatEnabled: {
            type: Boolean
        },
        closeHref: {},
        isLoyaltySwitcherEnabled: {
            type: Boolean
        },
        isSideBarOpened: {
            type: Boolean
        },
        isCashbackDisplay: {
            type: Boolean
        },
        timeout: {}
    },
    emits: [
        "click-chat",
        "fullscreen",
        "favorite",
        "close",
        "side-bar-toggle",
        "go-to-real-game",
        "go-to-login",
        "error-click",
        "click-switch",
        "restart-game",
        "set-frame-loaded",
        "on-frame-error"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { wrapperStyles, isSideBarOverGame, isVpnGame, hasGameDescription, onLoaderMounted, onIFrameLoaded } = useCasinoGameStart(props);
        const { isLoggedIn } = useIsLoggedIn();
        function onFrameLoaded() {
            emit('set-frame-loaded');
            onIFrameLoaded();
        }
        // TODO: get from EGSGame when provided
        const iframeAllow = computed(()=>'absolute_live_gaming' === props.activeGame.group.id ? 'fullscreen *, autoplay *' : 'autoplay; fullscreen; camera; microphone');
        useDevToolsDetector(()=>{
            emit('close');
        });
        const isFrameBlocked = ref(false);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-game-page']]: true,
                    [_ctx.$style['casino-game-page--demo-bar-visible']]: void 0
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['casino-game-page__window-wrapper'])
                }, [
                    (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['casino-game-page__window-wrapper-image']),
                        style: _normalizeStyle(_unref(wrapperStyles))
                    }, null, 6)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['casino-game-page__window'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['casino-game-page__window-inner']]: true
                            })
                        }, [
                            (_openBlock(), _createBlock(CasinoControlBar, {
                                key: 0,
                                title: _ctx.activeGame.name,
                                "is-favorite": _ctx.activeGame.isFavorite,
                                "is-checked-switch": !_ctx.isDemo,
                                "is-demo-enabled": _ctx.activeGame.hasDemo,
                                "is-chat-enabled": _ctx.isChatEnabled,
                                "close-href": _ctx.closeHref,
                                "is-loyalty-switcher-enabled": _ctx.isLoyaltySwitcherEnabled,
                                "is-over-game": _unref(isSideBarOverGame),
                                "is-side-bar-opened": _ctx.isSideBarOpened,
                                onClickChat: _cache[0] || (_cache[0] = ($event)=>emit('click-chat')),
                                onClose: _cache[1] || (_cache[1] = ($event)=>emit('close')),
                                onClickSwitch: _cache[2] || (_cache[2] = ($event)=>emit('click-switch', $event)),
                                onFavorite: _cache[3] || (_cache[3] = ($event)=>emit('favorite')),
                                onFullscreen: _cache[4] || (_cache[4] = ($event)=>emit('fullscreen')),
                                onSideBarToggle: _cache[5] || (_cache[5] = ($event)=>emit('side-bar-toggle')),
                                onRestartGame: _cache[6] || (_cache[6] = ($event)=>emit('restart-game'))
                            }, null, 8, [
                                "title",
                                "is-favorite",
                                "is-checked-switch",
                                "is-demo-enabled",
                                "is-chat-enabled",
                                "close-href",
                                "is-loyalty-switcher-enabled",
                                "is-over-game",
                                "is-side-bar-opened"
                            ])),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['casino-game-page__game']]: true,
                                    [_ctx.$style['casino-game-page__game--error']]: !!_ctx.error
                                })
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['casino-game-page__game-badges'])
                                }, [
                                    _ctx.activeGame.ntBadge ? (_openBlock(), _createBlock(VBadge, {
                                        key: 0,
                                        kind: _unref(BadgeKind).SQUARE_ERROR,
                                        label: _ctx.activeGame.ntBadge,
                                        class: _normalizeClass(_ctx.$style['casino-game-page__nt-badge'])
                                    }, null, 8, [
                                        "kind",
                                        "label",
                                        "class"
                                    ])) : _createCommentVNode("", true)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['casino-game-page__iframe-wrapper'])
                                }, [
                                    _ctx.isFrameLoaded ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        (_openBlock(), _createBlock(CasinoGameLoader, {
                                            key: 1,
                                            class: _normalizeClass(_ctx.$style['casino-game-page__loader'])
                                        }, null, 8, [
                                            "class"
                                        ]))
                                    ], 64)),
                                    _ctx.error ? (_openBlock(), _createBlock(CasinoGameIframeError, _mergeProps({
                                        key: 1
                                    }, _ctx.error, {
                                        class: _ctx.$style['casino-game-page__iframe-error'],
                                        onClick: _cache[7] || (_cache[7] = ($event)=>emit('error-click', $event))
                                    }), null, 16, [
                                        "class"
                                    ])) : _createCommentVNode("", true),
                                    _ctx.isLoading || _ctx.error ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 2
                                    }, [
                                        _createVNode(EgsGameIframe, {
                                            "game-url": _ctx.gameUrl,
                                            "start-code-type": _ctx.startCodeType,
                                            allow: iframeAllow.value,
                                            allowfullscreen: "",
                                            timeout: _unref(isVpnGame) ? void 0 : _ctx.timeout,
                                            class: _normalizeClass(_ctx.$style['casino-game-page__iframe']),
                                            onLoad: onFrameLoaded,
                                            onError: _cache[8] || (_cache[8] = ($event)=>emit('on-frame-error', $event))
                                        }, null, 8, [
                                            "game-url",
                                            "start-code-type",
                                            "allow",
                                            "timeout",
                                            "class"
                                        ]),
                                        isFrameBlocked.value ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['casino-game-page__block'])
                                        }, null, 2)) : _createCommentVNode("", true),
                                        _createCommentVNode("", true)
                                    ], 64))
                                ], 2),
                                _ctx.isLoading || _ctx.error ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['casino-game-page__bonuses-container'])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['casino-game-page__bonuses-item'])
                                    }, [
                                        (_openBlock(), _createBlock(CasinoGameBonusContainer, {
                                            key: 0,
                                            layout: _unref(PanelLayout).SLOTS_BAR,
                                            onWithdrawBonus: _cache[11] || (_cache[11] = ($event)=>emit('restart-game'))
                                        }, null, 8, [
                                            "layout"
                                        ]))
                                    ], 2),
                                    _ctx.isCashbackDisplay ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['casino-game-page__bonuses-item'])
                                    }, [
                                        _createVNode(CasinoGameCashbackContainer)
                                    ], 2)) : _createCommentVNode("", true)
                                ], 2))
                            ], 2)
                        ], 2),
                        _createCommentVNode("", true),
                        _ctx.isLoyaltySwitcherEnabled ? (_openBlock(), _createBlock(CasinoGameSideBarContainer, {
                            key: 1,
                            "is-over-game": _unref(isSideBarOverGame),
                            "is-hide-my-bonuses": _ctx.isCashbackDisplay
                        }, null, 8, [
                            "is-over-game",
                            "is-hide-my-bonuses"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['casino-game-page__gradient'])
                    }, null, 2))
                ], 2),
                (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['casino-game-page__footer'])
                }, [
                    _createVNode(CasinoGameListContainer),
                    _createVNode(CasinoGameLeaderBoard),
                    _unref(hasGameDescription) ? (_openBlock(), _createBlock(CasinoGameDescription, {
                        key: 0,
                        game: _ctx.activeGame,
                        "is-open": ""
                    }, null, 8, [
                        "game"
                    ])) : _createCommentVNode("", true)
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameStart'
                ]
            ]);
        };
    }
});
