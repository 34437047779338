import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { useCasinoGameBonus } from '../composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameBonusPanel',
    props: {
        item: {},
        isClickable: {
            type: Boolean
        },
        isNarrowed: {
            type: Boolean
        }
    },
    emits: [
        "click-bonus-details",
        "withdraw-bonus"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { bonusTitle, balance, isBonusActive, isProgressComplete, progress, expiringThrough, amount, requiredAmount, onClickBonusDetails, onClickWithdrawalBonus } = useCasinoGameBonus(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.item ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__title'])
                }, _toDisplayString(_unref(bonusTitle)), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__informer-wrapper'])
                }, [
                    _createElementVNode("div", null, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__informer'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__balance-label'])
                            }, _toDisplayString(_ctx.$t('JSPACC_ACC_BALANCE')), 3),
                            _unref(isProgressComplete) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__balance'])
                            }, _toDisplayString(_unref(balance)), 3)),
                            _unref(isBonusActive) ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(onClickBonusDetails) && _unref(onClickBonusDetails)(...args);
                                })
                            }, [
                                _createVNode(_unref(VIcon), {
                                    name: _unref(IconName).INFO_OUTLINE,
                                    size: _unref(IconSize).SIZE_16,
                                    class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__icon'])
                                }, null, 8, [
                                    "name",
                                    "size",
                                    "class"
                                ])
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _unref(isProgressComplete) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__balance'])
                        }, _toDisplayString(_unref(balance)), 3)) : _createCommentVNode("", true)
                    ]),
                    _unref(isProgressComplete) ? (_openBlock(), _createBlock(VButton, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__button']),
                        height: _unref(ButtonHeight).MEDIUM,
                        kind: _unref(ButtonKind).PRIMARY,
                        label: _ctx.$t('WEB2_GRAB'),
                        onClick: _unref(onClickWithdrawalBonus)
                    }, null, 8, [
                        "class",
                        "height",
                        "kind",
                        "label",
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _unref(isProgressComplete) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__progress'])
                    }, [
                        _createVNode(VLinearProgress, {
                            height: _unref(ProgressHeight).TINY,
                            value: _unref(progress),
                            class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__progress-line'])
                        }, null, 8, [
                            "height",
                            "value",
                            "class"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__info'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__info-date'])
                        }, _toDisplayString(`${_ctx.$t('WEB2_REMAINING')}: ${_unref(expiringThrough)}`), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__info-text'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__info-text--current'])
                            }, _toDisplayString(_unref(amount)), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['bonus-casino-slots-panel__info-text--divider'])
                            }, "/", 2),
                            _createElementVNode("span", null, _toDisplayString(_unref(requiredAmount)), 1)
                        ], 2)
                    ], 2)
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameBonusPanel'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
