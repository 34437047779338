import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const useCasinoBetgamesStore = defineStore('casino-betgames', ()=>{
    const { isLoggedIn } = useIsLoggedIn();
    const { startGame: startGameApi } = useEgsApi();
    const startGameData = ref();
    async function startGame() {
        startGameData.value = await startGameApi({
            id: 'betgames',
            isDemo: !isLoggedIn.value
        });
    }
    function clearStartGameData() {
        startGameData.value = void 0;
    }
    return {
        startGameData,
        startGame,
        clearStartGameData
    };
});
export default useCasinoBetgamesStore;
