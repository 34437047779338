import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { WebSocketAccessRole } from '@leon-hub/websocket';
import useWebSockets from './useWebSockets';
export function useLifecycleWebSockets(options) {
    const websockets = useWebSockets();
    let socketSubscribeResult;
    onComponentActivated(()=>{
        socketSubscribeResult = websockets.subscribe(options);
    });
    onComponentDeactivated(()=>{
        socketSubscribeResult?.unsubscribe();
        socketSubscribeResult = void 0;
    });
}
export function useAuthorizedLifecycleWebSockets(options) {
    useLifecycleWebSockets({
        ...options,
        access: WebSocketAccessRole.AUTHORIZED
    });
}
