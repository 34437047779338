import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useBonusStore } from 'web/src/modules/bonuses/store';
export default function useCasinoGameBonusContainer(emit) {
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const formatMoney = useFormatMoney();
    const bonusStore = useBonusStore();
    const bonus = toRef(()=>bonusStore.bonusCbc);
    const withdrawMoney = computed(()=>{
        if (!bonus.value) return '';
        const { walletBalanceNumber, currency } = bonus.value;
        return formatMoney(walletBalanceNumber, {
            currency
        });
    });
    async function onWithdrawBonusCallback() {
        await bonusStore.doWithdrawBonusCBC();
        emit('withdraw-bonus');
        await bonusStore.fetchBonusWagers();
    }
    function onWithdrawBonus(withdrawalFunction) {
        withdrawalFunction(onWithdrawBonusCallback);
    }
    async function redirectToBonusDetails(item) {
        await router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: item?.categoryId?.toLowerCase() ?? item.category.toLowerCase(),
                actionUrl: item.actionUrl
            }
        });
    }
    return {
        isLoggedIn,
        withdrawMoney,
        bonus,
        getBonuses: bonusStore.fetchBonusWagers,
        onWithdrawBonus,
        redirectToBonusDetails
    };
}
