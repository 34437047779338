export default function mapActiveGameToAnalytics(game) {
    const { categories, images } = game;
    return {
        game: {
            id: game.id,
            name: game.name,
            category: categories[0]?.nameEn || '',
            image: images.preview?.src || '',
            provider: {
                game: game.name,
                id: game.providerId
            }
        }
    };
}
